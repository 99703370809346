const variables = [
  "nachname",
  "vorname",
  "email",
  "telefon",
  "datum",
  "earlybirdBis",
  "deposit",
  "offen",
  "soll",
  "bezahlt",
  "conditions",
  "start",
  "title",
  "msg",
];

export default variables;
