import { Fragment, useContext, useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import i18n from '../../../i18n';
import { getLink } from '../../../types/evnt';
import { EventPartContext } from './EventPartProvider';
import InlineEdit from './InlineEdit';

type Props = {
  idx: number;
  id: string;
};

const LinkCmpt = (props: Props): JSX.Element => {
  const { event, update, remove } = useContext(EventPartContext);
  const [title, setTitle] = useState('');
  const [href, setHref] = useState('');

  useEffect(() => {
    if (event) {
      const link = getLink(event, props.id);
      if (link) {
        setTitle(link.title);
        setHref(link.href);
      }
    }
  }, [event, i18n.language]);

  const updateTitle = (value: string) => {
    if (value !== title) {
      setTitle(value);
      update('Link', props.id, 'title', value);
    }
  };

  const updateHref = (value: string) => {
    if (value !== href) {
      setHref(value);
      update('Link', props.id, 'href', value);
    }
  };

  const deleteLink = () => {
    if (event) {
      remove('Link', props.id);
    }
  };

  return (
    <Fragment>
      <tr>
        <td>
          <a className="link-primary" target="_blank" rel="noreferrer" href={href}>
            {title}
          </a>
        </td>
        <td>
          <FaTimes
            title="Delete link"
            className="text-danger float-end"
            size={21}
            onClick={deleteLink}
          />
        </td>
      </tr>
      <tr>
        <td colSpan={2}>
          <InlineEdit text={title} onSetText={updateTitle} />
        </td>
      </tr>
      <tr>
        <td colSpan={2}>
          <InlineEdit text={href} onSetText={updateHref} />
        </td>
      </tr>
    </Fragment>
  );
};
export default LinkCmpt;
