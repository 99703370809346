import { EventPartProvider } from "../Editor/EventPartProvider";
import { EventProvider } from "../Editor/EventProvider";
import List from "./List";

const RegList = (): JSX.Element => {
  return (
    <EventProvider>
      <EventPartProvider>
        <List />
      </EventPartProvider>
    </EventProvider>
  );
};

export default RegList;
