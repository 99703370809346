import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { getTeachers, Teacher } from '../../../types/evnt';
import { EventPartContext } from './EventPartProvider';
import TeacherCmpt from './teacher';

const Teachers = (): JSX.Element => {
  const { event, create } = useContext(EventPartContext);
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const [t] = useTranslation();
  const addTeacher = () => {
    if (event) {
      create('Teacher', { teacher: 'name' });
    }
  };

  useEffect(() => {
    if (event) {
      const te = getTeachers(event);
      if (te) setTeachers(te);
    }
  }, [event]);

  return (
    <div className="teachers">
      <Table>
        <caption>
          <h4>
            {t('teachers', {ns:process.env.REACT_APP_APPID})}
            <FaPlus
              title="Add teacher"
              className="text-success float-end"
              size={21}
              onClick={addTeacher}
            />
          </h4>
        </caption>
        <tbody>
          {_.map(
            _(teachers)
              .sortBy((x) => x.id)
              .value(),
            (teacher) => {
              if (teacher) return <TeacherCmpt key={teacher.id} id={teacher.id} />;
            }
          )}
        </tbody>
      </Table>
    </div>
  );
};
export default Teachers;
