import {
  useCreateMailTemplateMutation,
  useGetMailTemplatesQuery,
  useUpdateMailTemplateMutation,
  useDeleteMailTemplateMutation,
} from "../../../services/Api_DynamoDB";
import { useState, useEffect, createContext } from "react";
import { MailTemplate } from "../../../types/MailTemplate";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Registration } from "../../../types/Registration";
import { Condition, Translation } from "../../../types/evnt";

interface MailsProps {
  children: React.ReactNode;
}

interface lookupType {
  nachname: string;
  vorname: string;
  email: string;
  telefon: string;
  datum: Date;
  offen: number;
  soll: number;
  bezahlt: number;
  earlybirdBis: Date;
  deposit: number;
  conditions: string;
  title: string;
  start: string;
}

interface Mail {
  Source: string;
  Destination: {
    ToAddresses: string[];
  };
  Message: {
    Subject: {
      Data: string;
      Charset: string;
    };
    Body: {
      Text: {
        Data: string;
        Charset: string;
      };
    };
  };
}

interface MailsState {
  isLoading: boolean;
  mailTemplates: MailTemplate[];
  error: FetchBaseQueryError | SerializedError | undefined;
  saveTemplate: (item: MailTemplate) => void;
  deleteTemplate: (id: string) => void;
  getLookup: (
    reg: Registration,
    conditions: Condition[],
    trans: Translation
  ) => lookupType | undefined;
  setupMail: (
    sender: string,
    receiver: string[],
    subject: string,
    message: string
  ) => Mail | undefined;
}

export const MailsContext = createContext<MailsState>({
  isLoading: false,
  mailTemplates: [],
  error: undefined,
  saveTemplate: () => {},
  deleteTemplate: () => {},
  getLookup: () => undefined,
  setupMail: () => undefined,
});

const useMailTemplates = (): MailsState => {
  const { data, error, isLoading } = useGetMailTemplatesQuery("");
  const [mailTemplates, setMailTemplates] = useState<MailTemplate[]>([]);
  const { i18n } = useTranslation();
  const [update, updateResult] = useUpdateMailTemplateMutation();
  const [create, createResult] = useCreateMailTemplateMutation();
  const [deleteTmpl, deleteResult] = useDeleteMailTemplateMutation();

  useEffect(() => {
    if (data && i18n.language)
      setMailTemplates(
        _(data.Items).filter({ language: i18n.language }).value()
      );
  }, [data, i18n.language]);

  useEffect(() => {
    if (updateResult && updateResult.data) {
      const updated = updateResult.data;
      const update = mailTemplates.map((tmp) =>
        tmp.id === updated.id ? updated : tmp
      );
      setMailTemplates(update);
    }
  }, [updateResult]);

  useEffect(() => {
    if (createResult && createResult.data) {
      setMailTemplates([...mailTemplates, createResult.data]);
    }
  }, [createResult]);

  useEffect(() => {
    if (deleteResult && deleteResult.data) {
      setMailTemplates(
        _(mailTemplates).reject({ id: deleteResult.data.id }).value()
      );
    }
  }, [deleteResult]);

  const saveTemplate = (item: MailTemplate) => {
    if (item.id) {
      update(item);
    } else {
      create(_.omit(item, "id"));
    }
  };
  const deleteTemplate = (id: string) => {
    deleteTmpl(id);
  };

  const getLookup = (
    reg: Registration,
    conditions: Condition[],
    trans: Translation
  ): lookupType | undefined => {
    if (reg && conditions) {
      const lookup = {
        nachname: reg.nachname,
        vorname: reg.vorname,
        email: reg.email,
        telefon: reg.telefon,
        datum: reg.date,
        offen: reg.offen,
        soll: reg.soll,
        bezahlt: reg.bezahlt,
        earlybirdBis: reg.earlybirdUntil,
        deposit: reg.deposit,
        conditions: _(conditions)
          .map((c) => c.condition)
          .join("\n"),
        title: trans.title,
        start: trans.start,
        msg: reg.msg,
      };
      return lookup;
    }
  };

  const setupMail = (
    sender: string,
    receiver: string[],
    subject: string,
    message: string
  ): Mail => {
    return {
      Source: sender,
      Destination: {
        ToAddresses: receiver,
      },
      Message: {
        Subject: {
          Data: subject,
          Charset: "UTF-8",
        },
        Body: {
          Text: {
            Data: message,
            Charset: "UTF-8",
          },
        },
      },
    };
  };

  return {
    isLoading,
    mailTemplates,
    error,
    saveTemplate,
    deleteTemplate,
    getLookup,
    setupMail,
  };
};

export const MailsProvider = ({ children }: MailsProps): JSX.Element => {
  const value = useMailTemplates();
  return (
    <MailsContext.Provider value={value}>{children}</MailsContext.Provider>
  );
};
