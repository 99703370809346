import ObjectID from "bson-objectid";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { DateRange, EventItem, getDates } from "../../../types/evnt";
import DateCmpt from "./date";
import { EventContext } from "./EventProvider";

const Dates = (): JSX.Element => {
  const { event, updateEvent } = useContext(EventContext);
  const [dates, setDates] = useState<DateRange[]>();

  useEffect(() => {
    if (event) {
      const dates = getDates(event);
      if (dates) setDates(dates);
    }
  }, [event]);

  const addDate = async () => {
    if (event) {
      const datarangeid = ObjectID().toHexString();
      const Items: EventItem[] = _(event.Items)
        .concat([
          {
            typeName: "DateRange",
            id: datarangeid,
            from: new Date().toISOString(),
            to: new Date().toISOString(),
          },
          {
            typeName: "DateTranslation",
            id: ObjectID().toHexString(),
            dateRange: datarangeid,
            language: "de",
            caption: "Überschrift",
            comment: "Kommentar",
          },
          {
            typeName: "DateTranslation",
            id: ObjectID().toHexString(),
            dateRange: datarangeid,
            language: "en",
            caption: "caption",
            comment: "comment",
          },
        ])
        .value();
      updateEvent({ id: event.id, Items });
    }
  };

  return (
    <div className="date">
      <Table>
        <caption>
          <h4>
            date
            <FaPlus
              title="Add date"
              className="text-success float-end"
              size={21}
              onClick={addDate}
            />
          </h4>
        </caption>
        <tbody>
          {_(dates)
            .sortBy((x) => x.id)
            .map((date) => <DateCmpt key={date.id} id={date.id} />)
            .value()}
        </tbody>
      </Table>
    </div>
  );
};

export default Dates;
