import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';
import { getSpecial } from '../../../types/evnt';
import { EventPartContext } from './EventPartProvider';
import InlineEdit from './InlineEdit';

type Props = {
  id: string;
};

const SpecialCmpt = (props: Props): JSX.Element => {
  const { i18n } = useTranslation();
  const { event, update, remove } = useContext(EventPartContext);
  const [special, setSpecial] = useState('');

  useEffect(() => {
    if (event) {
      const special = getSpecial(event, props.id);
      if (special) setSpecial(special.special);
    }
  }, [event, i18n.language]);

  const updateSpecial = (text: string) => {
    if (text !== special) {
      setSpecial('speichern...');
      update('Special', props.id, 'special', text);
    }
  };

  const deleteSpecial = () => {
    remove('Special', props.id);
  };

  return (
    <tr>
      <td>
        <InlineEdit text={special} onSetText={updateSpecial} />
      </td>
      <td>
        <FaTimes
          title="Delete event"
          className="text-danger float-end"
          size={21}
          onClick={deleteSpecial}
        />
      </td>
    </tr>
  );
};
export default SpecialCmpt;
