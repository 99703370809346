import { Container } from "react-bootstrap";
import BottomRow from "./bottomRow";
import MiddleRow from "./MiddleRow";
import TopRow from "./TopRow";

const Index = (): JSX.Element => {
  return (
    <Container>
      <TopRow />
      <MiddleRow />
      <BottomRow />
    </Container>
  );
};
export default Index;
