import { MailsProvider } from "./MailsProvider";
import Page from "./page";

const MailTemplates = (): JSX.Element => {
  return (
    <MailsProvider>
      <Page />
    </MailsProvider>
  );
};

export default MailTemplates;
