import React, { useState } from "react";
import { useGetFrontendQuery } from "../../../services/Api_DynamoDB";
import { useParams } from "react-router-dom";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { Event } from "../../../types/evnt";

interface EventState {
  event: Event | undefined;
  isLoading: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
}

export const EventContext = React.createContext<EventState>({
  event: undefined,
  isLoading: false,
  error: undefined,
});

interface EventProps {
  children: React.ReactNode;
}

export const useEvent = (): EventState => {
  const { eventid } = useParams();
  const { data, error, isLoading } = useGetFrontendQuery(
    eventid ? eventid : ""
  );
  const [event, setEvent] = useState<Event>();
  React.useEffect(() => {
    if (data) {
      setEvent(data);
    }
  }, [data]);

  return { event, isLoading, error };
};

export const EventProvider = ({ children }: EventProps): JSX.Element => {
  const evt = useEvent();
  return <EventContext.Provider value={evt}>{children}</EventContext.Provider>;
};

export {};
