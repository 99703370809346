import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { useTranslation } from 'react-i18next';
import InlineEdit from './InlineEdit';
import { BsCalendar } from 'react-icons/bs';
import { EventPartContext } from './EventPartProvider';

type PriceProps = {
  name: string;
};

const Price = (props: PriceProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const { eventRecord, updateRecord } = useContext(EventPartContext);

  const [until, setUntil] = useState(new Date());
  const [amount, setAmount] = useState('');
  const [slide, setSlide] = useState(false);
  const [range1, setRange1] = useState('');
  const [range2, setRange2] = useState('');

  useEffect(() => {
    if (eventRecord) {
      const tarif = _(eventRecord.tarifs).find((x) => x.id == props.name);
      if (tarif) {
        setUntil(moment.utc(tarif.untilDate).toDate());
        setAmount(tarif.amount.toFixed(2));
        setSlide(tarif.slidingscale);
        setRange1(tarif.range1.toFixed(2));
        setRange2(tarif.range2.toFixed(2));
      }
    }
  }, [eventRecord]);

  const updateUntil = (value: Date) => {
    if (value !== until) {
      setUntil(value);
      if (eventRecord) {
        const idx = _(eventRecord.tarifs).findIndex((x) => x.id == props.name);
        if (idx >= 0) updateRecord('tarifs[' + idx.toString() + '].untilDate', value);
      }
    }
  };

  const updateAmount = (value: string) => {
    if (value !== amount) {
      if (eventRecord) {
        const idx = _(eventRecord.tarifs).findIndex((x) => x.id == props.name);
        if (idx >= 0) updateRecord('tarifs[' + idx.toString() + '].amount', value);
        setAmount(value);
      }
    }
  };

  const updateSlide = (value: boolean) => {
    if (value !== slide) {
      setSlide(value);
      if (eventRecord) {
        const idx = _(eventRecord.tarifs).findIndex((x) => x.id == props.name);
        if (idx >= 0) updateRecord('tarifs[' + idx.toString() + '].slidingscale', value);
      }
    }
  };

  const updateRange1 = (value: string) => {
    if (value !== range1) {
      setRange1(value);
      if (eventRecord) {
        const idx = _(eventRecord.tarifs).findIndex((x) => x.id == props.name);
        if (idx >= 0) updateRecord('tarifs[' + idx.toString() + '].range1', value);
      }
    }
  };

  const updateRange2 = (value: string) => {
    if (value !== range2) {
      setRange2(value);
      if (eventRecord) {
        const idx = _(eventRecord.tarifs).findIndex((x) => x.id == props.name);
        if (idx >= 0) updateRecord('tarifs[' + idx.toString() + '].range2', value);
      }
    }
  };

  return (
    <tbody>
      <tr>
        <th>{t(props.name)}</th>

        <th>
          {props.name === 'earlybird' ? (
            <DatePicker
              value={until}
              onChange={updateUntil}
              calendarIcon={<BsCalendar />}
              clearIcon={null}
              locale={i18n.language}
            />
          ) : (
            ''
          )}
        </th>
      </tr>
      <tr>
        <td>{t('amountPrice')}</td>
        <td>
          € <InlineEdit text={amount} onSetText={updateAmount} />
        </td>
      </tr>
      <tr>
        <td colSpan={2}>
          <Form.Switch
            id={'switch' + props.name}
            label="Slidingscale"
            checked={slide}
            onChange={() => {
              updateSlide(!slide);
            }}
          />
        </td>
      </tr>
      <tr>
        <td colSpan={2}>
          € <InlineEdit text={range1} onSetText={updateRange1} /> - €{' '}
          <InlineEdit text={range2} onSetText={updateRange2} />
        </td>
      </tr>
    </tbody>
  );
};
export default Price;
