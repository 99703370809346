import React, { useState } from "react";
import { Event, EventItem } from "../../../types/evnt";
import {
  useCreateMutation,
  useGetQuery,
  useUpdateMainMutation,
} from "../../../services/Api_DynamoDB";
import { useNavigate, useParams } from "react-router-dom";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import _ from "lodash";
import { useTranslation } from "react-i18next";

interface EventState {
  event: Event | undefined;
  setEvent: React.Dispatch<React.SetStateAction<Event | undefined>>;
  isLoading: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  copyEvent: (tag: string, rejectTypes: string[]) => void;
  updateEvent: (e: Event) => void;
}

export const EventContext = React.createContext<EventState>({
  event: undefined,
  setEvent: () => {},
  isLoading: false,
  error: undefined,
  copyEvent: () => {},
  updateEvent: () => {},
});

interface EventProps {
  children: React.ReactNode;
}

export const useEvent = (): EventState => {
  const { eventid } = useParams();
  const { data, error, isLoading } = useGetQuery(eventid ? eventid : "");
  const [createEvent, createResult] = useCreateMutation();
  const [update, updateResult] = useUpdateMainMutation();
  const [event, setEvent] = useState<Event>();
  const { i18n } = useTranslation();
  const navi = useNavigate();

  React.useEffect(() => {
    if (data) {
      setEvent(data);
    }
  }, [data, i18n.language]);

  const copyEvent = (tag: string, rejectTypes: string[]) => {
    if (event) {
      const Items = _(event.Items)
        .reject((x) => _(rejectTypes).includes(x.typeName))
        .map<EventItem>((x) => ("tag" in x ? { ...x, tag } : x))
        .map<EventItem>((x) =>
          "title" in x && tag == "active"
            ? { ...x, title: "Neu: " + x.title }
            : x
        )
        .value();
      createEvent(Items);
    }
  };

  React.useEffect(() => {
    if (createResult.data) {
      navi("/backend/" + createResult.data.id + "/editor");
    }
  }, [createResult]);

  const updateEvent = (e: Event) => {
    update(e);
  };

  React.useEffect(() => {
    if (updateResult.data) {
      setEvent(updateResult.data);
    }
  }, [updateResult]);

  return { event, setEvent, isLoading, error, copyEvent, updateEvent };
};

export const EventProvider = ({ children }: EventProps): JSX.Element => {
  const evt = useEvent();

  return <EventContext.Provider value={evt}>{children}</EventContext.Provider>;
};

export {};
