import React, { useState, useEffect, useRef, useCallback } from 'react';
import useKeyPress from '../../../hooks/useKeyPress';
import useOnClickOutside from '../../../hooks/useOnClickOutside';

type Props = {
  text: string;
  onSetText: (text: string) => void;
};

function InlineEdit(props: Props): JSX.Element {
  const [isInputActive, setIsInputActive] = useState(false);
  const [inputValue, setInputValue] = useState(props.text);

  const wrapperRef = useRef<HTMLSpanElement>(null);
  const textRef = useRef<HTMLSpanElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const enter = useKeyPress('Enter');
  const esc = useKeyPress('Escape');

  // check to see if the user clicked outside of this component
  useOnClickOutside(wrapperRef, () => {
    if (isInputActive) {
      props.onSetText(inputValue);
      setIsInputActive(false);
    }
  });

  const onEnter = useCallback(() => {
    if (enter) {
      props.onSetText(inputValue);
      setIsInputActive(false);
    }
  }, [enter, inputValue, props.onSetText]);

  const onEsc = useCallback(() => {
    if (esc) {
      setInputValue(props.text);
      setIsInputActive(false);
    }
  }, [esc, props.text]);

  // focus the cursor in the input field on edit start
  useEffect(() => {
    if (isInputActive && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isInputActive]);

  useEffect(() => {
    if (isInputActive) {
      // if Enter is pressed, save the text and close the editor
      onEnter();
      // if Escape is pressed, revert the text and close the editor
      onEsc();
    }
  }, [onEnter, onEsc, isInputActive]); // watch the Enter and Escape key presses

  const handleInputChange = useCallback(
    (event) => {
      // sanitize the input a little
      //setInputValue(DOMPurify.sanitize(event.target.value));
      setInputValue(event.target.value);
    },
    [setInputValue]
  );

  const handleSpanClick = () => {
    setInputValue(props.text);
    setIsInputActive(true);
  };

  // const handleSpanClick = useCallback(() => {
  //   setInputValue(props.text);
  //   setIsInputActive(true);
  // }, [setIsInputActive]);

  return (
    <span className="inline-text" ref={wrapperRef}>
      <span
        ref={textRef}
        onClick={handleSpanClick}
        className={`inline-text_copy inline-text_copy--${!isInputActive ? 'active' : 'hidden'}`}
        dangerouslySetInnerHTML={{ __html: props.text }}></span>
      <input
        ref={inputRef}
        // set the width to the input length multiplied by the x height
        // it's not quite right but gets it close
        //style={{ minWidth: '100%' }} // inputValue?Math.ceil(inputValue.length)%80 + "ch":'1ch' }}
        style={{ minWidth: inputValue ? (Math.ceil(inputValue.length) % 80) + 'ch' : '1ch' }}
        //cols={inputValue?Math.ceil(inputValue.length)%50:2}
        value={inputValue}
        onChange={handleInputChange}
        className={`inline-text_input inline-text_input--${isInputActive ? 'active' : 'hidden'}`}
      />
    </span>
  );
}

export default InlineEdit;
