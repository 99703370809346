import { Card, Row, Col } from 'react-bootstrap';
import Transportation from './transportation';
import Description from './description';
import Registration from './registration';
import Dates from './dates';
import Teachers from './teachers';
import Specials from './specials';
import Links from './links';
import Location from './location';
import Price from './pricesTable';
import { EventContext } from './EventProvider';
import Title from './title';
import ImageCmpt from './image';
import { Fragment, useContext } from 'react';
import Spin from '../../Diverse/Spin';
const EventCmpt = (): JSX.Element => {
  const { isLoading } = useContext(EventContext);
  return (
    <Fragment>
      {isLoading ? (
        <Spin />
      ) : (
        <Card>
          <ImageCmpt />
          <Card.Header>
            <Title />
          </Card.Header>

          <Card.Body className="panel-event">
            <Row>
              <Col sm={8}>
                <Description />
                <Transportation />
              </Col>
              <Col sm={4}>
                <Registration />
                <Dates />
                <Price />
                <Teachers />
                <Location />
                <Specials />
                <Links />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}{' '}
    </Fragment>
  );
};
export default EventCmpt;
