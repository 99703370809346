import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { getLinks, Link } from '../../../types/evnt';
import { EventPartContext } from './EventPartProvider';
import LinkCmpt from './link';

const Links = (): JSX.Element => {
  const { event, create } = useContext(EventPartContext);
  const [links, setLinks] = useState<(Link | undefined)[]>([]);
  const {t}=useTranslation();

  useEffect(() => {
    if (event) {
      setLinks(getLinks(event));
    }
  }, [event]);

  const addLink = () => {
    if (event) {
      create('Link', { title: 'new link', href: 'url...' });
    }
  };

  return (
    <div className="links">
      {
        <Table>
          <caption>
            <h4>
              {t('links')}
              <FaPlus
                title="Add link"
                className="text-success float-end"
                size={21}
                onClick={addLink}
              />
            </h4>
          </caption>
          <tbody>
            {_.map(links, (link, idx) => {
              if (link) return <LinkCmpt key={link.id} id={link.id} idx={idx} />;
            })}
          </tbody>
        </Table>
      }
    </div>
  );
};

export default Links;
