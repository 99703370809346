import { Row } from 'react-bootstrap';

const BottomRow = (): JSX.Element => {
  return (
    <Row>
      <div className="col-sm-4 col-md-4 col-lg-4 col-xl-25">
        <div id="redgreen" className="col-sm-12 sq-sm-4 sq-md-4 sq-lg-4  sq-xl-15"></div>
      </div>
      <div className="col-sm-4 col-md-4 col-lg-4 col-xl-25">
        <div id="group" className="col-sm-12 sq-sm-4 sq-md-4 sq-lg-4  sq-xl-15"></div>
      </div>
      <div className="col-sm-4 col-md-4 col-lg-4 col-xl-15">
        <div id="hands" className="col-sm-12 sq-sm-4 sq-md-4 sq-lg-4  sq-xl-15"></div>
      </div>
    </Row>
  );
};
export default BottomRow;
