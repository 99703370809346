import { useCallback, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import useKeyPress from "../../../hooks/useKeyPress";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

export const Texteditor = (props: {
  label: string;
  txt: string;
  onChange: (txt: string) => void;
}): JSX.Element => {
  const [text, setText] = useState("");
  const [position, setPosition] = useState(0);
  const htmlArea = useRef<HTMLTextAreaElement>();
  const [tab, setTab] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const esc = useKeyPress("Escape");

  // check to see if the user clicked outside of this component
  useOnClickOutside(htmlArea, () => {
    if (isActive) {
      setIsActive(false);
      if (text !== props.txt) props.onChange(text);
    }
  });

  const onEsc = useCallback(() => {
    if (esc) {
      setText(props.txt);
    }
  }, [esc, props.txt]);

  useEffect(() => {
    onEsc();
  }, [onEsc]);

  useEffect(() => {
    setText(props.txt);
  }, [props.txt]);

  return (
    <Form.Control
      as="textarea"
      // eslint-disable-next-line
      ref={htmlArea as any}
      placeholder={"enter " + props.label}
      style={{
        fontFamily: "Hack, monospace",
        fontSize: "9pt",
        whiteSpace: "nowrap",
        tabSize: 2,
      }}
      rows={text === "" ? 5 : text.split(/\r\n|\r|\n/).length + 1}
      value={text}
      onChange={(e) => {
        setText(e.target.value);
        //props.onChange(e.target.value);
      }}
      onSelect={(e) => {
        if (
          e.target instanceof HTMLTextAreaElement &&
          e.target.selectionStart
        ) {
          if (tab) {
            e.target.selectionStart = position;
            e.target.selectionEnd = position;
            setTab(false);
          } else {
            setPosition(e.target.selectionStart);
          }
        }
        setIsActive(true);
      }}
      onKeyDown={(e) => {
        if (e.key === "Tab") {
          e.preventDefault();
          if (text) {
            if (text.length >= position) {
              const result = [
                text.slice(0, position),
                "\t",
                text.slice(position),
              ].join("");
              setText(result);
              //props.onChange(result);
              setPosition(position + 1);
              setTab(true);
            }
          }
        }
      }}
    />
  );
};
