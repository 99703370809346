import Cognito, { CognitoUser } from '@aws-amplify/auth';
import React from 'react';

export type AuthChallengeName =
  | "NEW_PASSWORD_REQUIRED"
  | "SMS_MFA"
  | "SOFTWARE_TOKEN_MFA"
  | "MFA_SETUP";

  export interface UserAttributes {
    sub: string;
    email: string;
    email_verified: string;
    name: string;
    updated_at: string;
    'custom:bytesQuota': string;
    'custom:bytesUsed': string;
}

  export type AuthUser =  CognitoUser & {
    challengeName: AuthChallengeName,
    attributes: UserAttributes
  }

interface AuthContextType {
  user: AuthUser | null;
  signin: (
    user: string,
    password: string,
    callback: VoidFunction,
    // eslint-disable-next-line
    fail: (err: any) => void
  ) => void;
  signout: (callback: VoidFunction) => void;
  completeNewPassword: (newPass: string, callback: VoidFunction) => void;
}

const initialAuthContext = {
  user: null,
  signin: () => {},
  signout: () => {},
  completeNewPassword: () => {}
};

export const currentAuthenticatedUser = (): {
  read(): CognitoUser | null | undefined;
} => {
  let status = 'pending';
  let result: CognitoUser | null;
  const suspender = Cognito.currentAuthenticatedUser().then(
    (r) => {
      status = 'success';
      result = r.data;
    },
    (e) => {
      status = 'error';
      result = e;
    }
  );
  return {
    read() {
      if (status === 'pending') {
        throw suspender;
      } else if (status === 'error') {
        throw result;
      } else if (status === 'success') {
        return result;
      }
    }
  };
};

export const AuthContext = React.createContext<AuthContextType>(initialAuthContext);

export function AuthProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const [user, setUser] = React.useState<AuthUser | null>(null);

  const signin = (
    email: string,
    password: string,
    callback: VoidFunction,
    // eslint-disable-next-line
    fail: (err: any) => void
  ) => {
    return Cognito.signIn(email, password)
      .then((user: AuthUser) => {
        setUser(user);
        callback();
      })

      .catch((err) => fail(err));
  };

  const signout = (callback: VoidFunction) => {
    user?.signOut();
    return Cognito.signOut().then(() => {
      setUser(null);
      callback();
    });
  };

  const completeNewPassword = (newPass: string, callback: VoidFunction) => {
    user?.completeNewPasswordChallenge(
      newPass,
      {},
      {
        onSuccess: () => {
          Cognito.currentAuthenticatedUser().then((user) => {
            setUser(user);
            callback();
          });
        },
        onFailure: (err) => {
          throw err;
        }
      }
    );
  };

  React.useEffect(() => {
    if (!user) {
      try {
        Cognito.currentAuthenticatedUser().then((user) => setUser(user));
      } catch (e) {
        console.log(e);
      }
    }
  }, [user]);

  const value = { user, signin, signout, completeNewPassword };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default useAuth;

export function useAuth(): AuthContextType {
  return React.useContext(AuthContext);
}
