import {
  Card,
  InputGroup,
  Button,
  Form,
  Row,
  Col,
  Collapse,
  Alert,
  ListGroup,
} from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaEuroSign } from "react-icons/fa";
import { useContext, useEffect, useState } from "react";
import Captcha from "./Captcha";
import useCheckbox from "./useCheckbox";
import useInput from "./useInput";
import {
  usePostFrontendMutation,
  useGetFrontendQuery,
  useSendMailMutation,
} from "../../../services/Api_DynamoDB";
import {
  Condition,
  getConditions,
  getTranslation,
  Translation,
} from "../../../types/evnt";
import _ from "lodash";
import { MailsContext } from "../../Backend/Mailtemplates/MailsProvider";
import { Registration } from "../../../types/Registration";

const Conditions = (): JSX.Element => {
  const state = useLocation().state as Registration;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [PostFrontend, PostFrontendResult] = usePostFrontendMutation();
  const [sendMail] = useSendMailMutation();
  const { eventid } = useParams();
  const { data } = useGetFrontendQuery(eventid ? eventid : "");
  const [conds, setConds] = useState<Condition[]>([]);
  const [trans, setTrans] = useState<Translation>();
  const { mailTemplates, getLookup, setupMail } = useContext(MailsContext);
  const [open, setOpen] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showPrice, setShowPrice] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(true);
  const [showCommit, setShowCommit] = useState(false);
  const confirm = useCheckbox();
  const pricebox = useInput(state.price.amount.toString());
  const [title, setTitle] = useState("");
  const [reg, setReg] = useState<Registration>();

  useEffect(() => {
    if (data) {
      const trans = getTranslation(data, i18n.language);
      if (trans) setTrans(trans);
      const cds = getConditions(data, i18n.language);
      setConds(cds);
      if (trans) setTitle(trans.title);
    }
  }, [data, i18n.language]);

  useEffect(() => {
    if (PostFrontendResult && reg && trans) {
      const lookup = getLookup(reg, conds, trans);
      if (lookup) {
        if (mailTemplates) {
          const template = _(mailTemplates).find((mt) => {
            return (
              mt.language === i18n.language &&
              mt.name === "AutoMail Participant"
            );
          });
          if (template) {
            const compiled = _.template(template.body);
            const body = compiled(lookup);
            const mail = setupMail(
              "donotreply@elskedance.de",
              [state.email],
              template.subject,
              body
            );
            if (mail) {
              sendMail(JSON.stringify(mail));
            }
          }
          const template2 = _(mailTemplates).find((mt) => {
            return (
              mt.language === i18n.language && mt.name === "AutoMail Organizer"
            );
          });
          if (template2) {
            const compiled = _.template(template2.body);
            const body = compiled(lookup);
            const mail = setupMail(
              "donotreply@elskedance.de",
              [template2.org_email],
              template2.subject,
              body
            );
            if (mail) {
              sendMail(JSON.stringify(mail));
            }
          }
        }
      }
      navigate("finish", { state: state });
    }
  }, [PostFrontendResult]);

  const CaptchaCallback = () => {
    setShowCaptcha(false);
    setShowCommit(true);
  };

  const GoBack = () => {
    navigate("/registration?eventid=" + state?.event, { state: state });
  };

  const Commit = () => {
    if (!confirm.checked) {
      setShowConfirm(true);
      return false;
    }
    const slide = state.price.slidingscale;
    if (slide && !pricebox.value) {
      setShowPrice(true);

      return false;
    }

    const newReg = {
      ...state,
      soll: _.toNumber(pricebox.value),
      offen: _.toNumber(pricebox.value),
    } as Registration;

    setReg(newReg);
    PostFrontend({ id: state.event, body: newReg });
  };

  return (
    <Form method="post">
      <Row>
        <Col sm={4}>
          <Row>
            <Col sm={12}>
              <Card>
                <Card.Header className="mt-2">
                  <Card.Title>
                    <h5>{t("correct")}</h5>
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <div className="mb-3">{t("commitment")}</div>
                  <div>{title}</div>
                  <address>
                    <strong>
                      {state.vorname} {state.nachname}
                    </strong>
                    <br />
                    {state.adresse.strasse} {state.adresse.nr}
                    <br />
                    {state.adresse.plz} {state.adresse.ort}
                    <br />
                    <abbr title="Telefon:">Tel:</abbr> {state.telefon}
                    <br />
                    Email: {state.email}
                  </address>
                  <Button
                    type="submit"
                    className="btn-info pull-left mb-2"
                    onClick={GoBack}
                  >
                    {t("back")}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col sm={8}>
          <Card>
            <Card.Header className="mt-2">
              <Card.Title>
                <h3>{t("Registration")}</h3>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm={12}>
                  <div>{t("risk")}</div>
                  <div>
                    {state.price.slidingscale ? (
                      <div>
                        <br />
                        {t("choose")} {state.price.range2.toFixed(2)} -{" "}
                        {state.price.range1.toFixed(2)}
                        <Form.Group className="mt-3 mb-3">
                          <Col sm={4}>
                            <InputGroup>
                              <InputGroup.Text>
                                <FaEuroSign />
                              </InputGroup.Text>
                              <input
                                id="pricebox"
                                className="form-control currency"
                                name="pricebox"
                                {...pricebox}
                                type="number"
                                min={state.price.range1}
                                max={state.price.range2}
                                step="10"
                                required
                              />
                            </InputGroup>
                          </Col>
                        </Form.Group>
                      </div>
                    ) : (
                      <div className="mt-3 mb-3">
                        {t("amount")} € {state.price.amount.toFixed(2)}
                      </div>
                    )}
                  </div>
                  <div className="checkbox">
                    <input id="confirmBox" type="checkbox" {...confirm} />{" "}
                    {t("Confirm")}
                    <a
                      onClick={() => setOpen(!open)}
                      aria-controls="terms"
                      aria-expanded={open}
                    >
                      <span className="link-primary">{t("terms")}</span>
                    </a>
                  </div>

                  <Collapse in={open}>
                    <Card id="terms">
                      <Card.Body>
                        <ListGroup>
                          {_(conds)
                            .map((x) => (
                              <ListGroup.Item key={x.id}>
                                {x.condition}
                              </ListGroup.Item>
                            ))
                            .value()}
                        </ListGroup>
                      </Card.Body>
                    </Card>
                  </Collapse>
                </Col>
              </Row>
              {showCaptcha ? (
                <Captcha
                  //lang={() => i18n.language}
                  callback={CaptchaCallback}
                />
              ) : (
                ""
              )}

              {showCommit ? (
                <Button
                  id="regButton"
                  className="btn-warning btn-lg mt-4"
                  onClick={Commit}
                >
                  {t("regButton")}
                </Button>
              ) : (
                ""
              )}
              {showConfirm ? (
                <Alert
                  variant="danger"
                  className="mt-4"
                  onClose={() => setShowConfirm(false)}
                  dismissible
                >
                  <p> {t("ConfirmError")}</p>
                </Alert>
              ) : (
                ""
              )}
              {showPrice ? (
                <Alert
                  variant="danger"
                  onClose={() => setShowPrice(false)}
                  dismissible
                >
                  <p> {t("PriceError")}</p>
                </Alert>
              ) : (
                ""
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Form>
  );
};

export default Conditions;
