import { useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getRegistration } from "../../../types/evnt";
import { Registration } from "../../../types/Registration";
import { EventContext } from "../Editor/EventProvider";

export const Participant = (props: { id: string }): JSX.Element => {
  const { event } = useContext(EventContext);
  const [reg, setReg] = useState<Registration>();
  const { t } = useTranslation();

  useEffect(() => {
    if (event) {
      const reg = getRegistration(event, props.id);
      if (reg) setReg(reg);
    }
  }, [event]);

  return (
    <>
      {reg ? (
        <Card>
          <Card.Header>
            <h4>{t("Participant")}</h4>
          </Card.Header>
          <Card.Body>
            <address>
              <strong>
                {reg.vorname} {reg.nachname}
              </strong>
              <br />
              {reg.adresse.strasse} {reg.adresse.nr}
              <br />
              {reg.adresse.plz} {reg.adresse.ort}
              <br />
              <abbr title="Telefon:">Tel:</abbr> {reg.telefon}
              <br />
              Email: <a href={"mailto:" + reg.email}>{reg.email}</a>
            </address>
          </Card.Body>
        </Card>
      ) : (
        ""
      )}
    </>
  );
};
