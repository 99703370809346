import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { Alert, Button, Col, Form, Nav, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaPlus, FaRegSave, FaTrashAlt } from "react-icons/fa";
import { MailTemplate } from "../../../types/MailTemplate";
import { MailsContext } from "./MailsProvider";
import variables from "./variables";

const Page = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const { mailTemplates, saveTemplate, deleteTemplate } =
    useContext(MailsContext);
  const [selected, setSelected] = useState<MailTemplate>();
  const [error, setError] = useState("");
  const [position, setPosition] = useState(0);

  useEffect(() => {
    if (mailTemplates.length > 0 && !selected) {
      setSelected(mailTemplates[0]);
    }
  }, [mailTemplates]);

  const newtemplate = () => {
    setSelected({
      typeName: "MailTemplate",
      id: "",
      name: t("New template"),
      subject: "",
      body: "",
      language: i18n.language,
      org_email: "",
    });
  };

  return (
    <Row>
      <Col md={3}>
        <h4>
          <>
            <span>{t("Mails")}</span>
            <a
              className="float-end"
              onClick={newtemplate}
              title={t("New template")}
            >
              <FaPlus size={21} />
            </a>
          </>
        </h4>
        <Nav className="flex-column">
          {_(mailTemplates)
            .map((template) => {
              return (
                <Nav.Item key={template.id}>
                  <Nav.Link
                    onClick={() => {
                      setError("");
                      setSelected(template);
                      setPosition(0);
                    }}
                  >
                    {template.name}
                  </Nav.Link>
                </Nav.Item>
              );
            })
            .value()}
        </Nav>
      </Col>
      <Col>
        {selected ? (
          <Form>
            <Button
              variant="outline-primary"
              className="float-end mb-3"
              onClick={() => {
                if (selected.body == "") setError("Text darf nicht leer sein");
                if (selected.subject == "")
                  setError("Betreff darf nicht leer sein");

                if (selected.name == "") setError("Name darf nicht leer sein");
                if (!error) saveTemplate(selected);
              }}
            >
              <FaRegSave />
            </Button>

            <Form.Group className=" mb-3" controlId="name">
              <Form.Label className="mt-5">Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter name"
                value={selected.name}
                onChange={(e) => {
                  setError("");
                  setSelected({ ...selected, name: e.target.value });
                }}
              />
            </Form.Group>

            {error ? (
              <Alert variant="danger" onClose={() => setError("")} dismissible>
                {error}
              </Alert>
            ) : (
              ""
            )}

            <Form.Group className="mb-3" controlId="subject">
              <Form.Label>{t("Subject")}</Form.Label>
              <Form.Control
                type="text"
                placeholder="subject"
                value={selected.subject}
                onChange={(e) => {
                  setError("");
                  setSelected({ ...selected, subject: e.target.value });
                }}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="body">
              <Form.Label>Text</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="body"
                rows={
                  selected.body == ""
                    ? 10
                    : selected.body.split(/\r\n|\r|\n/).length + 1
                }
                value={selected.body}
                onChange={(e) => {
                  setError("");
                  setSelected({ ...selected, body: e.target.value });
                }}
                onSelect={(e) => {
                  if (
                    e.target instanceof HTMLTextAreaElement &&
                    e.target.selectionStart
                  ) {
                    setPosition(e.target.selectionStart);
                  }
                }}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="email">
              <Form.Label>{t("Email")}</Form.Label>
              <Form.Control
                type="text"
                placeholder="your email"
                value={selected.org_email}
                onChange={(e) => {
                  setError("");
                  setSelected({ ...selected, org_email: e.target.value });
                }}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="language">
              <Form.Label>{t("Language")}</Form.Label>
              <Form.Control type="text" value={selected.language} readOnly />
            </Form.Group>
            <Button
              variant="outline-danger"
              className="ms-3 float-end mb-3"
              disabled={selected.id === ""}
              onClick={() => {
                setSelected(undefined);
                deleteTemplate(selected.id);
              }}
            >
              <FaTrashAlt />
            </Button>
          </Form>
        ) : (
          ""
        )}
      </Col>
      <Col sm={2}>
        <h3 className="mt-5">Variables</h3>
        <Nav className="flex-column">
          {_(variables)
            .map((variable) => {
              return (
                <Nav.Item key={variable}>
                  <Nav.Link
                    onClick={() => {
                      if (selected) {
                        const text = selected.body;
                        if (text.length >= position) {
                          const result = [
                            text.slice(0, position),
                            "${" + variable + "}",
                            text.slice(position),
                          ].join("");
                          setSelected({ ...selected, body: result });
                        }
                      }
                    }}
                  >
                    {variable}
                  </Nav.Link>
                </Nav.Item>
              );
            })
            .value()}
        </Nav>
      </Col>
    </Row>
  );
};

export default Page;
