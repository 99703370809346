import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { Row, Col, Image } from "react-bootstrap";
import { Event, getEventRecord } from "../../../types/evnt";
import ListCard from "./ListCard";
import { ListContext } from "./ListProvider";

const Cards = (): JSX.Element => {
  const { list } = useContext(ListContext);
  const bucket = process.env.REACT_APP_IMAGE_BUCKET;
  const url =
    "https://" +
    bucket +
    ".s3." +
    process.env.REACT_APP_REGION +
    ".amazonaws.com/public/";

  const [image, setImage] = useState("");

  useEffect(() => {
    if (list) {
      _(list).forEach((event: Event) => {
        const ev = getEventRecord(event);
        if (ev && ev.frontpage) setImage(url + ev.image);
      });
    }
  }, [list]);

  return (
    <>
      {image != "" ? (
        <Row>
          <Col>
            <Image src={image} className="w-100"></Image>
          </Col>
        </Row>
      ) : (
        ""
      )}
      <Row>
        <Col lg={6}>
          <ListCard Category="cat1" Eventtype={1} />{" "}
          <ListCard Category="cat4" Eventtype={4} />{" "}
          <ListCard Category="cat5" Eventtype={5} />
        </Col>
        <Col lg={6}>
          <ListCard Category="cat3" Eventtype={3} />{" "}
          <ListCard Category="cat2" Eventtype={2} />
        </Col>
      </Row>
    </>
  );
};
export default Cards;
