import { useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getEventRecord } from '../../../types/evnt';
import { EventContext } from './EventProvider';

const Location = (): JSX.Element => {
  const {t}=useTranslation();
  const { event } = useContext(EventContext);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');

  useEffect(() => {
    if (event) {
      const rec = getEventRecord(event);
      if (rec) {
        setName(rec.location);
        setAddress(rec.address);
      }
    }
  }, [event]);

  return (
    <div className="location">
      <Table>
        <caption>
          <h4>{t('location')}</h4>
        </caption>
        <tbody>
          <tr>
            <td>
              <b>{name}</b>
              <div>{address}</div>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};
export default Location;
