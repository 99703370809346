import { FormEvent, useEffect, useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./useAuth";

const Login = (): JSX.Element => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { user, signin } = useAuth();

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    try {
      signin(
        email,
        password,
        () => setError(""),
        (err) => {
          setError(err.message);
        }
      );
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      }
    }
  }
  useEffect(() => {
    if (user) {
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        navigate("/changePassword");
      } else {
        navigate("/backend");
      }
    }
  }, [user]);

  return (
    <div className="Login">
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            size="lg"
            autoFocus
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            size="lg"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Button
          className="mt-3"
          size="lg"
          type="submit"
          disabled={!validateForm()}
        >
          Login
        </Button>
        {error != "" ? (
          <Alert
            variant="danger"
            className="mt-4"
            onClose={() => setError("")}
            dismissible
          >
            <p>{error} </p>
          </Alert>
        ) : (
          ""
        )}
      </Form>
    </div>
  );
};

export default Login;
