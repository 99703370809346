import Amplify from "@aws-amplify/core";

export function configureAmplify(): void {
  Amplify.configure({
    Auth: {
      userPoolId: process.env.REACT_APP_USERPOOL,
      userPoolWebClientId: process.env.REACT_APP_USERPOOLCLIENT,
      identityPoolId: process.env.REACT_APP_IDENTITYPOOL,
      region: process.env.REACT_APP_REGION,
      cookieStorage: {
        domain: document.location.hostname,
        secure: false,
        path: "/",
        expires: 365,
      },
    },
    Storage: {
      bucket: process.env.REACT_APP_IMAGE_BUCKET,
      region: process.env.REACT_APP_REGION,
      identityPoolId: process.env.REACT_APP_IDENTITYPOOL_ID,
    },
    Analytics: {
      disabled: true,
    },
  });
}
