import { Card } from 'react-bootstrap';
import 'leaflet/dist/leaflet.css';
import L, { LatLngTuple } from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { EventContext } from './EventProvider';
import { getEventRecord, getTranslation } from '../../../types/evnt';

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});
L.Marker.prototype.options.icon = DefaultIcon;

const Transportation = (): JSX.Element => {
  const { event } = useContext(EventContext);
  const { i18n } = useTranslation();
  const [body, setBody] = useState('');
  const [name, setName] = useState('');
  const [latlng, setLatlng] = useState<LatLngTuple>([51.505, -0.09]);
  const mapContainer = useRef<HTMLDivElement | null>();
  const [mapref, setMapref] = useState<L.Map>();

  useEffect(() => {
    if (mapref) mapref.remove();
    if (mapContainer.current) {
      const tiles =
        'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}';
      const attribution =
        '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>';
      const accessToken =
        'pk.eyJ1IjoianNlaWRlbDY3IiwiYSI6ImNqczB1aTU1NjA0czM0M28xZjIzNHU0OW0ifQ.auDxh4Wf6WRiltE0CvSwaw';
      const id = 'mapbox/streets-v11';
      const map = L.map(mapContainer.current, { attributionControl: true }).setView(latlng, 14);
      L.tileLayer(tiles, {
        maxZoom: 18,
        attribution,
        id,
        tileSize: 512,
        zoomOffset: -1,
        accessToken
      }).addTo(map);
      L.marker(latlng).addTo(map).bindTooltip(name).openTooltip();
      setMapref(map);
    }
  }, [latlng]);

  useEffect(() => {
    if (event) {
      const rec = getEventRecord(event);
      const tx = getTranslation(event, i18n.language);
      if (tx) setBody(tx.transportation);
      if (rec) {
        setLatlng([rec.latitude, rec.longitude]);
        setName(rec.location);
      }
    }
  }, [i18n.language, event]);

  return (
    <Card className="transportation">
      {event ? (
        <div
          style={{ padding: 0, margin: 0, width: '100%', height: '40vh' }}
          ref={(el) => (mapContainer.current = el)}></div>
      ) : (
        ''
      )}
      <Card.Body>
        <div dangerouslySetInnerHTML={{ __html: body }}></div>
      </Card.Body>
    </Card>
  );
};

export default Transportation;
