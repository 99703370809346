import { ChangeEvent, useState } from "react";
import { Col, Row, InputGroup, FormControl, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import Resizer from "react-image-file-resizer";
import { Storage } from "aws-amplify";

interface Props {
  update?: (file: string) => void;
}

const ImgUpload = (props: Props): JSX.Element => {
  const [msg, setMsg] = useState("");
  const { t } = useTranslation();

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.files) {
      const selected = event.target.files[0];
      setMsg("");

      if (!selected) {
        setMsg("Please select image.");
        return false;
      }

      if (!selected.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
        setMsg("Please select valid image JPG,JPEG,PNG");
        return false;
      }

      Resizer.imageFileResizer(
        selected,
        1294,
        728,
        "JPEG",
        85,
        0,
        (data) => {
          const file = data as File;
          Storage.put(selected.name, file, {
            level: "public",
            contentType: "image/jpeg",
          })
            .then((result) => {
              if (file && props.update) props.update(result.key);
            })
            .catch((err) => console.log(err));
        },
        "file"
      );
    }
  };

  return (
    <Row>
      <Col className="mb-3">
        <InputGroup>
          <FormControl
            type="file"
            onChange={(e) =>
              handleInputChange(e as ChangeEvent<HTMLInputElement>)
            }
          />
        </InputGroup>
        {msg != "" ? (
          <Alert variant="danger" onClose={() => setMsg("")} dismissible>
            <p> {t(msg)}</p>
          </Alert>
        ) : (
          ""
        )}
      </Col>
    </Row>
  );
};
export default ImgUpload;
