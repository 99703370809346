import _ from 'lodash';
import { useDeleteMutation, useGetByTagQuery } from '../../../services/Api_DynamoDB';
import { useState, useEffect, createContext, Dispatch, SetStateAction } from 'react';
import { Event } from '../../../types/evnt';

interface ListProps {
  children: React.ReactNode;
}

interface ListState {
  isLoading: boolean;
  list: Event[];
  setList: Dispatch<SetStateAction<Event[]>>;
  deleteTemplate: (idx: number) => void;
}

export const ListContext = createContext<ListState>({
  isLoading: false,
  list: [],
  setList: () => {},
  deleteTemplate: () => {}
});

const useList = (): ListState => {
  const [removeEvent] = useDeleteMutation();
  const [list, setList] = useState<Event[]>([]);
  const { data = [], isLoading } = useGetByTagQuery('template');

  useEffect(() => {
    if (data.length > 0) {
      setList(data);
    }
  }, [data]);

  const deleteTemplate = (idx: number) => {
    const template = list[idx];
    if (template) {
      removeEvent(template.id);
      setList(
        _(list)
          .reject((_, i) => i == idx)
          .value()
      );
    }
  };

  return { isLoading, list, setList, deleteTemplate };
};

export const ListProvider = ({ children }: ListProps): JSX.Element => {
  const list = useList();

  return <ListContext.Provider value={list}>{children}</ListContext.Provider>;
};
