import _ from "lodash";
import { Fragment, useContext, useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  getConditions,
  getRegistration,
  getTranslation,
} from "../../../types/evnt";
import { MailTemplate } from "../../../types/MailTemplate";
import { Texteditor } from "../Custompages/Texteditor";
import { EventPartContext } from "../Editor/EventPartProvider";
import { RiMailSendLine } from "react-icons/ri";
import { useSendMailMutation } from "../../../services/Api_DynamoDB";
import useAuth from "../../Auth/useAuth";
import moment from "moment";
import { MailsContext } from "../Mailtemplates/MailsProvider";

export const Emails = (props: {
  regid: string;
  template?: MailTemplate;
}): JSX.Element => {
  const { i18n, t } = useTranslation();
  const [email, setEmail] = useState("");
  const { event, create } = useContext(EventPartContext);
  const [lookup, setLookup] = useState({ email: "" });
  const [receiver, setReceiver] = useState("");
  const [subject, setSubject] = useState("");
  const [name, setName] = useState("");
  const [sendMail] = useSendMailMutation();
  const { user } = useAuth();
  const [cc, setCC] = useState("");
  const { getLookup, setupMail } = useContext(MailsContext);

  useEffect(() => {
    if (user) {
      setCC(user.attributes.email);
    }
  }, [user]);

  useEffect(() => {
    if (event && props.regid) {
      const cds = getConditions(event, i18n.language);
      const reg = getRegistration(event, props.regid);
      const trans = getTranslation(event, i18n.language);
      if (reg && cds && trans) {
        const lookup = getLookup(reg, cds, trans);
        if (lookup) {
          setLookup(lookup);
        }
      }
    }
  }, [event, i18n.language]);

  useEffect(() => {
    if (props.template && lookup) {
      const compiled = _.template(props.template.body);
      setEmail(compiled(lookup));
      setReceiver(lookup.email);
      setSubject(props.template.subject);
      setName(props.template.name);
    }
  }, [props.template, lookup]);

  return (
    <Fragment>
      <InputGroup className=" mb-3">
        <Form.Control
          type="text"
          readOnly
          className=""
          placeholder="Receiver"
          value={receiver}
        />
        <Button
          variant="primary"
          className=""
          onClick={() => {
            const mail = setupMail(
              "donotreply@elskedance.de",
              [receiver, cc],
              subject,
              email
            );
            create("Note", {
              typeName: "Note",
              regId: props.regid,
              datum: moment(Date.now()).format("YYYY-MM-DD hh:mm"),
              text: "Email: " + name + " " + t("sent"),
            });
            if (mail) sendMail(JSON.stringify(mail));
          }}
        >
          <RiMailSendLine />
          <span className="ms-3">Send</span>
        </Button>
      </InputGroup>

      <Form.Control
        type="text"
        className="mb-3"
        placeholder="Subject"
        value={subject}
        onChange={(e) => {
          setSubject(e.target.value);
        }}
      />
      <Texteditor
        label="Email"
        txt={email}
        onChange={(text) => {
          setEmail(text);
        }}
      />
    </Fragment>
  );
};
