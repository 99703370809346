import React, { createContext, useContext, useState } from "react";
import {
  Event,
  EventItem,
  EventRecord,
  getEventRecord,
} from "../../../types/evnt";
import {
  useUpdateFieldPartMutation,
  useCreatePartMutation,
  useDeletePartMutation,
} from "../../../services/Api_DynamoDB";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { EventContext } from "./EventProvider";

/*eslint-disable */
interface EventPartState {
  event: Event | undefined;
  eventRecord: EventRecord | undefined;
  create: (typeName: string, body: any) => void;
  update: (typeName: string, id: string, field: string, value: any) => void;
  remove: (typeName: string, id: string) => void;
  createThen: (
    typeName: string,
    body: any,
    callback: (data: EventItem) => void
  ) => void;
  updateRecord: (field: string, value: any) => void;
}

export const EventPartContext = createContext<EventPartState>({
  event: undefined,
  eventRecord: undefined,
  create: () => {},
  update: () => {},
  remove: () => {},
  createThen: (
    typeName: string,
    body: any,
    callback: (data: EventItem) => void
  ) => {},
  updateRecord: () => {},
});
/*eslint-enable */

interface EventPartProps {
  children: React.ReactNode;
}

export const useEventPart = (): EventPartState => {
  const [createPart, createResult] = useCreatePartMutation();
  const [updatePart, updateResult] = useUpdateFieldPartMutation();
  const [deletePart, deleteResult] = useDeletePartMutation();
  const { event, setEvent } = useContext(EventContext);
  const [eventRecord, setEventRecord] = useState<EventRecord>();

  const { i18n } = useTranslation();

  React.useEffect(() => {
    if (event) {
      const rec = getEventRecord(event);
      if (rec) setEventRecord(rec);
    }
  }, [event, i18n.language]);

  React.useEffect(() => {
    if (createResult.data && event) {
      setEvent({
        id: event.id,
        Items: _.concat(event.Items, createResult.data),
      });
    }
  }, [createResult.data]);

  React.useEffect(() => {
    if (updateResult.data && event) {
      const y = updateResult.data;
      setEvent({
        id: event.id,
        Items: _(event.Items)
          .map((x) => (x.id == y.id ? y : x))
          .value(),
      });
    }
  }, [updateResult.data]);

  React.useEffect(() => {
    if (deleteResult.data && event) {
      const y = deleteResult.data;
      if (y) {
        setEvent({
          id: event.id,
          Items: _(event.Items)
            .reject((x) => x.id == y.id)
            .value(),
        });
      }
    }
  }, [deleteResult.data]);
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const create = (typeName: string, body: any) => {
    if (event) {
      createPart({ id: event.id, typeName, body });
    }
  };

  const createThen = (
    typeName: string,
    body: any,
    callback: (data: EventItem) => void
  ) => {
    createPart({ id: event ? event.id : "", typeName, body }).then((value) => {
      if ("data" in value) callback(value.data);
    });
  };

  const updateRecord = (field: string, value: any) => {
    if (event && eventRecord) {
      updatePart({
        id: event.id,
        typeName: eventRecord.typeName,
        key: eventRecord.id,
        body: { field, value },
      });
    }
  };

  const update = (typeName: string, key: string, field: string, value: any) => {
    if (event) {
      updatePart({
        id: event.id,
        typeName,
        key,
        body: { field, value },
      });
    }
  };

  const remove = async (typeName: string, key: string) => {
    if (event) {
      deletePart({ id: event.id, typeName, key });
    }
  };
  return {
    event,
    eventRecord,
    create,
    createThen,
    update,
    updateRecord,
    remove,
  };
};

export const EventPartProvider = ({
  children,
}: EventPartProps): JSX.Element => {
  const evt = useEventPart();
  return (
    <EventPartContext.Provider value={evt}>
      {children}
    </EventPartContext.Provider>
  );
};

export {};
