import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import i18n from '../../../i18n';
import { getConditions, Condition } from '../../../types/evnt';
import ConditionCmpt from './condition';
import { EventPartContext } from './EventPartProvider';

const Conditions = (): JSX.Element => {
  const { event, create } = useContext(EventPartContext);
  const [conditions, setConditions] = useState<Condition[]>([]);

  useEffect(() => {
    if (event) setConditions(getConditions(event, i18n.language));
  }, [event, i18n.language]);

  const addCondition = () => {
    const language = i18n.language;
    create('Condition', { language, condition: 'condition' });
  };

  return (
    <div className="conditions">
      <Table>
        <caption>
          <h4>
            conditions
            <FaPlus
              title="Add condition"
              className="text-success float-end"
              size={21}
              onClick={addCondition}
            />
          </h4>
        </caption>
        <tbody>
          {_.map(conditions, (condition) => {
            if (condition) return <ConditionCmpt key={condition.id} id={condition.id} />;
          })}
        </tbody>
      </Table>
    </div>
  );
};
export default Conditions;
