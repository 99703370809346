import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";
import InlineEdit from "./InlineEdit";
import DatePicker from "react-date-picker";
import moment from "moment";
import { BsCalendar } from "react-icons/bs";
import InlineTextArea from "./InlineTextArea";
import {
  getDate,
  getDateTranslation,
  getDateTranslations,
} from "../../../types/evnt";
import { EventPartContext } from "./EventPartProvider";
import _ from "lodash";

type Props = {
  id: string;
};

const DateCmpt = (props: Props): JSX.Element => {
  const { i18n } = useTranslation();
  const [caption, setCaption] = useState("");
  const [comment, setComment] = useState("");
  const [dtId, setDtId] = useState("");
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());
  const { event, update, remove } = useContext(EventPartContext);

  useEffect(() => {
    if (event) {
      const dt = getDate(event, props.id);
      if (dt) {
        setFrom(moment.utc(dt.from).toDate());
        setTo(moment.utc(dt.to).toDate());
        const dtt = getDateTranslation(event, props.id, i18n.language);
        if (dtt) {
          setCaption(dtt.caption);
          setComment(dtt.comment);
          setDtId(dtt.id);
        }
      }
    }
  }, [event, i18n.language]);

  const updateCaption = (text: string) => {
    if (text !== caption) {
      update("DateTranslation", dtId, "caption", text);
      setCaption("Speichern...");
    }
  };

  const updateComment = (text: string) => {
    if (text !== comment) {
      update("DateTranslation", dtId, "comment", text);
      setComment("Speichern...");
    }
  };

  const updateFrom = (text: Date) => {
    if (text !== from) {
      update("DateRange", props.id, "from", moment(text).format("yyyy-MM-DD"));
      setFrom(text);
    }
  };

  const updateTo = (text: Date) => {
    if (text !== to) {
      update("DateRange", props.id, "to", moment(text).format("yyyy-MM-DD"));
      setTo(text);
    }
  };

  const deleteDate = () => {
    if (event) {
      _(getDateTranslations(event, props.id)).forEach((x) => {
        if (x) remove("DateTranslation", x.id);
      });
      remove("DateRange", props.id);
    }
  };

  return (
    <tr>
      <td>
        <div>
          <b>
            <InlineEdit text={caption} onSetText={updateCaption} />
          </b>
          <FaTimes
            title="Delete date"
            className="text-danger float-end"
            size={21}
            onClick={deleteDate}
          />
        </div>
        <div className="mt-1 mb-1">
          <DatePicker
            value={new Date(from)}
            onChange={updateFrom}
            calendarIcon={<BsCalendar />}
            clearIcon={null}
            locale={i18n.language}
          />{" "}
          -{" "}
          <DatePicker
            value={new Date(to)}
            onChange={updateTo}
            calendarIcon={<BsCalendar />}
            clearIcon={null}
            locale={i18n.language}
          />
        </div>
        <div>
          <InlineTextArea text={comment} onSetText={updateComment} />
        </div>
      </td>
    </tr>
  );
};

export default DateCmpt;
