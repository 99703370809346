import { createSlice } from "@reduxjs/toolkit";

const idTokenSlice = createSlice({
    name: 'idToken',
    initialState: {
        idToken: null
    },
    reducers: {
      setIdToken: (state, action) => {
        state.idToken = action.payload;
      }
    }
  });
  export default idTokenSlice.reducer;
  
  // Actions
  
  export const { setIdToken } = idTokenSlice.actions;