import { useState, useEffect, useContext } from "react";
import {
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  NavDropdown,
  Container,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {} from "react-redux";
import { useAuth } from "./Auth/useAuth";
import moment from "moment";
import { PagesContext } from "./PageProvider";
import _ from "lodash";
import { Page } from "../types/Pages";
/* eslint-disable  @typescript-eslint/no-explicit-any */
const NavBar = (): JSX.Element => {
  const { i18n, t } = useTranslation();
  const [name, setName] = useState("");
  const navi = useNavigate();
  const { user, signout } = useAuth();
  const [custompages, setCustompages] = useState<Page[]>([]);

  const { isLoading, pages } = useContext(PagesContext);

  useEffect(() => {
    if (user && user.attributes && user.attributes.name) {
      setName(user.attributes.name);
    } else {
      setName("");
    }
  }, [user]);

  useEffect(() => {
    setCustompages(
      _(pages)
        .filter((x) => x.active)
        .reject((x) => x.link === "home")
        .sortBy((x) => x.index)
        .value()
    );
  }, [pages]);

  if (!isLoading)
    return (
      <Navbar
        collapseOnSelect
        variant="light"
        bg="light"
        fixed="top"
        expand="lg"
      >
        <Container>
          <NavbarBrand onClick={() => navi("/")}>
            {t("brand", { ns: process.env.REACT_APP_APPID })}
          </NavbarBrand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto" navbar>
              <NavItem>
                <NavLink
                  eventKey="0"
                  onClick={() => {
                    navi("/events");
                  }}
                >
                  {t("Events & Registration")}
                </NavLink>
              </NavItem>
              {_(custompages)
                .map((page) => {
                  return (
                    <NavItem key={page.id}>
                      <NavLink
                        eventKey={page.index}
                        onClick={() => navi("/page/" + page.link)}
                      >
                        {t(page.title)}
                      </NavLink>
                    </NavItem>
                  );
                })
                .value()}
              {/* <NavItem>
              <NavLink eventKey="1" onClick={() => navi("/page/Videos")}>
                {t("Videos")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink eventKey="2" onClick={() => navi("/page/Photos")}>
                {t("Photos")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink eventKey="3" onClick={() => navi("/page/About")}>
                {t("About")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink eventKey="4" onClick={() => navi("/page/Contact")}>
                {t("Contact")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink eventKey="5" onClick={() => navi("/page/Impressum")}>
                {t("Impressum")}
              </NavLink>
            </NavItem> */}
            </Nav>
            <Nav className="float-lg-end">
              {user ? (
                <NavItem>
                  <NavDropdown title={name} id="backendMenu" className="me-3">
                    <NavDropdown.Item
                      eventKey="100"
                      onClick={() => navi("/backend")}
                    >
                      {t("Events")}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      eventKey="101"
                      onClick={() =>
                        navi("/archive/" + moment().format("YYYY"))
                      }
                    >
                      {t("Archive")}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      eventKey="102"
                      onClick={() => navi("/templates")}
                    >
                      {t("Templates")}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      eventKey="103"
                      onClick={() => navi("/mailtemplates")}
                    >
                      {t("Mails")}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      eventKey="104"
                      onClick={() => navi("/custompages/")}
                    >
                      {t("Custompages")}
                    </NavDropdown.Item>
                  </NavDropdown>
                </NavItem>
              ) : (
                ""
              )}

              {i18n.language === "de" ? (
                <NavItem>
                  <NavLink
                    className="me-3"
                    eventKey="de"
                    onClick={(e) => {
                      i18n.changeLanguage("en");
                      e.preventDefault();
                    }}
                  >
                    <span className="fw-bold text-dark">de</span>
                    <span className="ps-1 pe-1">/</span> en
                  </NavLink>
                </NavItem>
              ) : (
                ""
              )}
              {i18n.language === "en" ? (
                <NavItem>
                  <NavLink
                    className="me-3"
                    eventKey="en"
                    onClick={(e) => {
                      i18n.changeLanguage("de");
                      e.preventDefault();
                    }}
                  >
                    de <span className="ps-1 pe-1">/</span>
                    <span className="fw-bold text-dark">en</span>
                  </NavLink>
                </NavItem>
              ) : (
                ""
              )}

              {user ? (
                <NavItem>
                  <NavLink
                    className=""
                    eventKey="ev_logout"
                    onClick={() => {
                      signout(() => navi("/"));
                    }}
                  >
                    Logout
                  </NavLink>
                </NavItem>
              ) : (
                <NavItem>
                  <NavLink
                    className=""
                    eventKey="ev_login"
                    onClick={() => navi("/login")}
                  >
                    Login
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  else return <></>;
};

export default NavBar;
