import { useContext, useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { getTeacher } from '../../../types/evnt';
import { EventPartContext } from './EventPartProvider';
import InlineEdit from './InlineEdit';

type Props = {
  id: string;
};

const TeacherCmpt = (props: Props): JSX.Element => {
  const { event, update, remove } = useContext(EventPartContext);
  const [name, setName] = useState('');

  useEffect(() => {
    if (event) {
      const t = getTeacher(event, props.id);
      if (t) setName(t.teacher);
    }
  }, [event]);

  const updateName = (text: string) => {
    if (text !== name) {
      setName('Speichern...');
      update('Teacher', props.id, 'teacher', text);
    }
  };

  const deleteTeacher = () => {
    remove('Teacher', props.id);
  };

  return (
    <tr>
      <td>
        <InlineEdit text={name} onSetText={updateName} />
        <FaTimes
          title="Delete teacher"
          className="text-danger float-end"
          size={21}
          onClick={deleteTeacher}
        />
      </td>
    </tr>
  );
};

export default TeacherCmpt;
