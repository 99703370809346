import { Card, InputGroup, Form, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useInput from "./useInput";
import { FaCheck, FaEnvelope, FaPhone } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import useTextArea from "./useTextarea";
import { useGetFrontendQuery } from "../../../services/Api_DynamoDB";
import { useEffect, useState } from "react";
import { getEventRecord, getTranslation, Tarif } from "../../../types/evnt";
import { Registration } from "../../../types/Registration";

const Index = (): JSX.Element => {
  const state = useLocation().state as Registration;

  // const [searchParams, setSearchParams] = useSearchParams();
  // const eventid = searchParams.get('eventid');
  const { eventid } = useParams();
  const { data } = useGetFrontendQuery(eventid ? eventid : "");
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (data) {
      const trans = getTranslation(data, i18n.language);
      if (trans) setTitle(trans.title);
    }
  }, [data]);

  const early = () => {
    if (data) {
      const event = getEventRecord(data);
      if (event) {
        const early = event.tarifs.find((x: Tarif) => x.id == "earlybird");
        if (early) return moment(early.untilDate).isAfter();
        else return false;
      } else return false;
    } else return false;
  };

  const earlyUntil = () => {
    if (data) {
      const event = getEventRecord(data);
      if (event) {
        const early = event.tarifs.find((x: Tarif) => x.id == "earlybird");
        if (early) return early.untilDate;
      }
    }
    return "";
  };

  const getPrice = () => {
    if (data) {
      const event = getEventRecord(data);
      if (event) {
        if (early()) {
          return event.tarifs.find((x: Tarif) => x.id == "earlybird");
        } else {
          return event.tarifs.find((x: Tarif) => x.id == "full");
        }
      }
    }
  };

  const getDeposit = () => {
    if (data) {
      const event = getEventRecord(data);
      if (event) {
        const dep = event.tarifs.find((x: Tarif) => x.id == "deposit");
        if (dep) return dep.amount;
      }
    }
    return 0;
  };

  const navigate = useNavigate();

  const handleSubmit = (evt: { preventDefault: () => void }) => {
    const price = getPrice();
    //const now = new Date().toISOString();
    const reg = {
      //typeName: 'Registration',
      nachname: lastname.value,
      vorname: firstname.value,
      tag: "active",
      adresse: {
        strasse: street.value,
        nr: nr.value,
        plz: code.value,
        ort: city.value,
      },
      telefon: phone.value,
      email: email.value,
      earlybird: early(),
      earlybirdUntil: earlyUntil(),
      event: eventid ? eventid : "",
      //id: '',
      start: moment(new Date()).format("yyyy-MM-DD"),
      date: new Date(),
      soll: price ? (price?.slidingscale ? price.range2 : price?.amount) : 0,
      bezahlt: 0,
      offen: price ? (price?.slidingscale ? price.range2 : price?.amount) : 0,
      //payments: [],
      status: "Neu",
      //notizen: [],
      msg: msg.value,
      deposit: getDeposit(),
      price: price
        ? price
        : {
            id: "",
            amount: 0,
            slidingscale: false,
            range1: 0,
            range2: 0,
            until: "",
          },
    };
    //setRegistration(reg);
    evt.preventDefault();
    navigate("conditions", { state: reg });
  };

  const { t, i18n } = useTranslation();
  const lastname = useInput(state ? state.nachname : "");
  const firstname = useInput(state ? state.vorname : "");
  const street = useInput(state ? state.adresse.strasse : "");
  const nr = useInput(state ? state.adresse.nr : "");
  const code = useInput(state ? state.adresse.plz : "");
  const city = useInput(state ? state.adresse.ort : "");
  const email = useInput(state ? state.email : "");
  const phone = useInput(state ? state.telefon : "");
  const msg = useTextArea(state ? state.msg : "");
  const next = t("Next") ? t("Next") : "";

  return (
    <Form onSubmit={handleSubmit} method="post">
      <Card className="mt-2 col-xs-12 col-sm-12 col-md-10 col-lg-8">
        <Card.Header className=" mt-2">
          <h2 className="card-title">{t("regtitle")}</h2>
          <div>{t("commitment")}</div>
          <div>{title}</div>
        </Card.Header>
        <Card.Body className="card-body">
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>{t("Lastname")}</Form.Label>
                <InputGroup>
                  <input
                    type="text"
                    {...lastname}
                    id="Nachname"
                    className="form-control"
                    placeholder={t("Lastname")}
                    required
                  />
                  <InputGroup.Text>
                    <FaCheck />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>{t("Firstname")}</Form.Label>
                <InputGroup>
                  <input
                    type="text"
                    {...firstname}
                    id="Vorname"
                    className="form-control"
                    placeholder={t("Firstname")}
                    required
                  />

                  <InputGroup.Text>
                    <FaCheck />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FaEnvelope />
                  </InputGroup.Text>
                  <input
                    type="email"
                    {...email}
                    className="form-control"
                    placeholder="Email"
                    required
                  />

                  <InputGroup.Text>
                    <FaCheck />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <div className="col-sm-8 has-feedback">
                    <Form.Label>{t("Street")}</Form.Label>
                    <input
                      type="text"
                      {...street}
                      className="form-control"
                      id="street"
                      name="Adresse.Strasse"
                      placeholder={t("Street")}
                      required
                    />
                  </div>
                  <div className="col-sm-4 has-feedback">
                    <Form.Label>{t("No.")}</Form.Label>
                    <InputGroup>
                      <input
                        type="text"
                        {...nr}
                        className="form-control"
                        id="nr"
                        name="Adresse.Nr"
                        placeholder={t("No.")}
                        required
                      />
                      <InputGroup.Text>
                        <FaCheck />
                      </InputGroup.Text>
                    </InputGroup>
                  </div>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <div className="col-sm-3">
                    <Form.Label>{t("Code")}</Form.Label>
                    <input
                      type="text"
                      {...code}
                      className="form-control"
                      id="plz"
                      placeholder={t("Code")}
                      required
                    />
                  </div>
                  <div className="col-sm-9">
                    <Form.Label>{t("City")}</Form.Label>
                    <InputGroup>
                      <input
                        type="text"
                        {...city}
                        className="form-control"
                        id="ort"
                        placeholder={t("City")}
                        required
                      />
                      <InputGroup.Text>
                        <FaCheck />
                      </InputGroup.Text>
                    </InputGroup>
                  </div>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>{t("Phone")}</Form.Label>
                <div className="input-group">
                  <InputGroup.Text>
                    <FaPhone />
                  </InputGroup.Text>
                  <input
                    type="tel"
                    {...phone}
                    className="form-control"
                    placeholder={t("Phone")}
                    required
                  />
                  <InputGroup.Text>
                    <FaCheck />
                  </InputGroup.Text>
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>{t("Questions")}</Form.Label>
                <textarea
                  name="Msg"
                  {...msg}
                  id="InputMessage"
                  className="form-control"
                  rows={5}
                ></textarea>
              </Form.Group>
              <Form.Group className="mb-3 ">
                <input
                  type="submit"
                  value={next}
                  className="btn btn-info float-end"
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Form>
  );
};
export default Index;
