import { Card, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Fragment, useContext, useEffect, useState } from 'react';
import { ListContext } from './ListProvider';
import Spin from '../../Diverse/Spin';
import Entry from './Entry';
import { getEventRecord } from '../../../types/evnt';

type Listprops = {
  Category: string;
  Eventtype: number;
};

const ListCard = (props: Listprops): JSX.Element => {
  const { t } = useTranslation();
  const { isLoading, list } = useContext(ListContext);
  const [keys, setKeys] = useState<string[]>([]);

  useEffect(() => {
    if (list) {
      const result = _(list)
        .filter((entity) => {
          const event = getEventRecord(entity);
          return event ? event.eventtype == props.Eventtype && event.visible : false;
        })
        .map((x) => x.id)
        .value();
      setKeys(result);
    }
  }, [list]);

  return (
    <Fragment>
      {keys.length > 0 ? (
        <Card>
          <Card.Header>
            <h2 className="ListHead">{t(props.Category)}</h2>
          </Card.Header>
          <Card.Body>
            {isLoading ? (
              <Spin />
            ) : (
              <Table hover>
                <tbody>
                  {keys.map((id) => {
                    return <Entry key={id} id={id} />;
                  })}
                </tbody>
              </Table>
            )}
          </Card.Body>
        </Card>
      ) : (
        ''
      )}
    </Fragment>
  );
};

export default ListCard;
