import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { FaCheck, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { getPayments, getRegistration } from "../../../types/evnt";
import { Payment } from "../../../types/Registration";
import { EventPartContext } from "../Editor/EventPartProvider";

type Props = {
  id: string;
};

const Entry = (props: Props): JSX.Element => {
  const navi = useNavigate();
  const { event, remove } = useContext(EventPartContext);
  const [date, setDate] = useState("");
  const [early, setEarly] = useState(false);
  const [status, setStatus] = useState("");
  const [nachname, setNachname] = useState("");
  const [vorname, setVorname] = useState("");
  const [telefon, setTelefon] = useState("");
  const [email, setEmail] = useState("");
  const [strasse, setStrasse] = useState("");
  const [nr, setNr] = useState("");
  const [plz, setPlz] = useState("");
  const [ort, setOrt] = useState("");
  const [msg, setMsg] = useState("");
  //const [msgShort, setMsgShort] = useState('');
  const [sum, setSum] = useState(0);
  const [payments, setPayments] = useState<Payment[]>([]);
  const [rest, setRest] = useState(0);

  useEffect(() => {
    if (event) {
      const reg = getRegistration(event, props.id);

      if (reg) {
        setDate(new Date(reg.date).toLocaleDateString());
        setEarly(reg.earlybird);
        setStatus(reg.status);
        setNachname(reg.nachname);
        setVorname(reg.vorname);
        setTelefon(reg.telefon);
        setEmail(reg.email);
        setStrasse(reg.adresse.strasse);
        setNr(reg.adresse.nr);
        setPlz(reg.adresse.plz);
        setOrt(reg.adresse.ort);
        setMsg(reg.msg);
        setRest(reg.soll);

        // if (reg.msg && reg.msg.length > 80) setMsgShort(reg.msg.substring(0, 80) + '...');
        // else setMsgShort(reg.msg);
      }
      const paymts = getPayments(event, props.id);
      if (reg && paymts.length > 0) {
        setPayments(paymts);
        const summe = _.sumBy(paymts, (value) => value.betrag);
        setSum(summe);
        setRest(reg.soll - summe);
      }
    }
  }, [event]);

  const View = () => {
    if (event) navi("/registrations/" + event.id + "/" + props.id);
  };

  const Remove = () => {
    remove("Registration", props.id);
    setShow(false);
  };
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  if (event)
    return (
      <tr>
        <td onClick={View}>{date}</td>
        <td onClick={View}> {early ? <FaCheck /> : ""}</td>
        <td onClick={View}>{status}</td>
        <td onClick={View}>
          {nachname}, {vorname}
          <br />
          <abbr title="Telefon:">Tel:</abbr> {telefon}
          <br />
          <a href="mailto:{{reg.email}}">{email}</a>
        </td>
        <td onClick={View}>
          {strasse} {nr}
          <br /> {plz} {ort}
        </td>
        <td onClick={View} title={msg}>
          {msg}
        </td>
        <td onClick={View}>
          <Table className="mt-n3">
            <tbody>
              {_.map(payments, (item, idx) => {
                return (
                  <tr key={idx}>
                    <td className="float-end"> €{item.betrag.toFixed(2)}</td>
                  </tr>
                );
              })}

              <tr key="Sum">
                <td className="float-end">Summe:&nbsp;€{sum.toFixed(2)} </td>
              </tr>
            </tbody>
          </Table>
        </td>
        <td onClick={View}>
          <div className="mt-2"></div> €{rest.toFixed(2)}
        </td>
        <td onClick={handleShow}>
          <FaTimes
            title="Delete registration"
            className="text-danger"
            size={21}
          />{" "}
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                Anmeldung {vorname} {nachname}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>Wirklich löschen?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Abbrechen
              </Button>
              <Button variant="primary" onClick={Remove}>
                Löschen
              </Button>
            </Modal.Footer>
          </Modal>
        </td>
      </tr>
    );
  else return <tr></tr>;
};

export default Entry;
