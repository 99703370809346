import { useState, useCallback, ChangeEvent } from 'react';

const useTextArea = (
  initialValue = ''
): {
  value: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
} => {
  const [value, setValue] = useState(initialValue);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => setValue(e.currentTarget.value),
    []
  );

  return {
    value,
    onChange
  };
};

export default useTextArea;
