import moment from "moment";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaGlobe, FaTimes, FaTrashRestore } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { getEventRecord, getTranslation } from "../../../types/evnt";
import { AListContext } from "./ListProvider";

type Props = {
  idx: number;
};

const Entry = (props: Props): JSX.Element => {
  const navi = useNavigate();
  const { i18n } = useTranslation();
  const { list, deleteArchived, restoreFromArchive } = useContext(AListContext);
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [frontpage, setFrontpage] = useState(false);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [start, setStart] = useState("");
  const [id, setId] = useState("");

  useEffect(() => {
    if (list && list.length > props.idx) {
      const event = list[props.idx];

      if (event) {
        setId(event.id);
        const rec = getEventRecord(event);
        if (rec) {
          setVisible(rec.visible);
          setOpen(rec.registration_open);
          setFrontpage(rec.frontpage);
          setStart(rec.start);
        }
        const tx = getTranslation(event, i18n.language);
        if (tx) {
          setTitle(tx.title);
          setSubtitle(tx.subtitle);
        }
      }
    }
  }, [list, i18n.language]);

  const View = () => {
    if (id) navi("/events/" + id);
  };

  const Remove = () => {
    deleteArchived(props.idx);
  };

  return (
    <Fragment>
      {list ? (
        <tr
          className={
            visible && open
              ? "bg-success bg-opacity-10"
              : visible && !open
              ? "bg-warning bg-opacity-10"
              : ""
          }
        >
          <td>
            {visible ? (
              <h4>
                <FaGlobe className={frontpage ? "text-danger" : ""} size={21} />{" "}
              </h4>
            ) : (
              ""
            )}
          </td>
          <td onClick={View}>
            <h4>
              <div> {title} </div>
              <small> {subtitle}</small>
            </h4>
          </td>
          <td>
            <FaTrashRestore
              title="Wiederherstellen"
              className="text-success"
              size={21}
              onClick={() => restoreFromArchive(props.idx)}
            />
          </td>
          <td>{moment(start).toDate().toLocaleDateString()}</td>
          <td>
            <FaTimes
              title="Delete event"
              className="text-danger"
              size={21}
              onClick={Remove}
            />
          </td>
        </tr>
      ) : (
        <tr>
          <td></td>
        </tr>
      )}
    </Fragment>
  );
};

export default Entry;
