import { Card, Row, Col } from 'react-bootstrap';
import Dates from './dates';
import Links from './links';
import PriceTable from './pricesTable';
import Registration from './registration';
import Specials from './specials';
import Teachers from './teachers';
import Title from './Title';
import Image from './Image';
import Transportation from './transportation';
import LocationCmpt from './location';
import Description from './description';
import { useContext, Fragment } from 'react';
import { EventContext } from './EventProvider';
import Spin from '../../Diverse/Spin';
import Conditions from './conditions';

const Editor = (): JSX.Element => {
  const { isLoading, error } = useContext(EventContext);
  return (
    <Fragment>
      {isLoading ? (
        <Spin />
      ) : (
        <Card>
          <Image />
          <Card.Header>
            <Title />
            {error ? <div>error</div> : ''}
          </Card.Header>
          <Card.Body className="panel-event">
            <Row>
              <Col sm={8}>
                <Description />
                <Transportation />
              </Col>
              <Col sm={4}>
                <Registration />
                <Dates />
                <PriceTable />
                <Teachers />
                <LocationCmpt />
                <Specials />
                <Links />
                <Conditions />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
    </Fragment>
  );
};
export default Editor;
