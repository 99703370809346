import { Row } from 'react-bootstrap';
import Frontpage from './Frontpage';

const MiddleRow = (): JSX.Element => {
  return (
    <Row className="d-lg-none row11">
      <div className="d-xs-block d-sm-none d-md-block col-md-8 col-lg-25">
        <div id="topleft2" className="col-sm-12 sq-sm-4 sq-md-3 sq-lg-15"></div>
      </div>
      <div className="col-sm-4 col-lg-25">
        <div id="news2" className="d-none d-md-block  col-md-12 sq-sm-4 sq-md-3 sq-lg-15">
          <Frontpage />
        </div>
      </div>
    </Row>
  );
};
export default MiddleRow;
