import { Card, Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Finish = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Row>
      <Col sm={12}>
        <Card>
          <Card.Header>
            <Card.Title>
              <h4>{t("thanks")}</h4>
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <div>
              {t("send")}
              <br />
              <br />
              Elske Seidel
              <br />
              Hamburger Sparkasse
              <br />
              KontoNr: 1238544447
              <br />
              BLZ: 20050550
              <br />
              IBAN: DE64200505501238544447
              <br />
              BIC CODE: HASPDEHHXXX
              <br />
              <br />
              {t("receipt")}
              <br />
            </div>
            <p>
              <Button className="btn-default pull-right mt-4" href="/">
                {t("continue")}
              </Button>
            </p>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Finish;
