import React from 'react';
import { WidgetInstance } from 'friendly-challenge';
import PropTypes from 'prop-types';

const FRIENDLYCAPTCHA_SITEKEY = 'FCMPQOET7U9TRUJL';
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */

export default class Captcha extends React.Component {
  widget = undefined;
  static propTypes = {
    callback: PropTypes.func.isRequired,
    lang: PropTypes.isRequired
  };

  constructor(props) {
    super(props);
    this.captchaWidgetRef = React.createRef();
    this.state = { solution: 'No solution yet' };
  }

  componentDidMount() {
    const el = this.captchaWidgetRef.current;
    this.widget = new WidgetInstance(el, {
      doneCallback: (solution) => {
        // The captcha was completed
        this.setState({ solution: solution });
        this.props.callback(solution);
      }
    });
  }

  resetCaptchaWidget() {
    // Reset the widget (a solution can only be used once, so usually you do this after submitting it).
    if (this.widget) this.widget.reset();
  }

  render() {
    return (
      <div
        className="frc-captcha"
        ref={this.captchaWidgetRef}
        data-sitekey={FRIENDLYCAPTCHA_SITEKEY}
        data-lang={this.props.lang}
        data-start="auto"></div>
    );
  }
}
