import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { ListContext } from './ListProvider';
import { getEventRecord, getTranslation } from '../../../types/evnt';
import moment from 'moment';

type Props = {
  id: string;
};

const Entry = (props: Props): JSX.Element => {
  const { i18n } = useTranslation();
  const navi = useNavigate();
  const { list } = useContext(ListContext);
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [start, setStart] = useState('');
  const [location, setLocation] = useState('');

  useEffect(() => {
    if (list.length > 0) {
      const entity = _(list).find((item) => item.id == props.id);
      if (entity) {
        const event = getEventRecord(entity);
        const trans = getTranslation(entity, i18n.language);
        if (trans) {
          setTitle(trans.title);
          setSubtitle(trans.subtitle);
        }
        if (event) {
          setStart(moment(event.start).toDate().toLocaleDateString());
          setLocation(event.location);
        }
      }
    }
  }, [list, i18n.language]);

  return (
    <tr>
      <td>
        <a className="link-dark" onClick={() => navi('/events/' + props.id)}>
          <h5 className="text-dark">
            {title}
            <br />
            <small className="text-muted">{subtitle}</small>
          </h5>
        </a>
      </td>
      <td>
        <a className="link-dark" onClick={() => navi('/events/' + props.id)}>
          {start}{' '}
        </a>
      </td>
      <td className=".d-none .d-sm-block">
        <a className="link-dark" onClick={() => navi('/events/' + props.id)}>
          <b>{location}</b>
        </a>
      </td>
    </tr>
  );
};

export default Entry;
