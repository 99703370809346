import { useGetByTagQuery } from "../../../services/Api_DynamoDB";
import { useState, useEffect, createContext } from "react";
import { Event } from "../../../types/evnt";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

interface ListProps {
  children: React.ReactNode;
}

interface ListState {
  isLoading: boolean;
  list: Event[];
  error: FetchBaseQueryError | SerializedError | undefined;
}

export const ListContext = createContext<ListState>({
  isLoading: false,
  list: [],
  error: undefined,
});

const useList = (): ListState => {
  const { data, error, isLoading } = useGetByTagQuery("active");

  const [list, setList] = useState<Event[]>([]);

  useEffect(() => {
    if (data && data.length > 0) setList(data);
  }, [data]);

  return { isLoading, list, error };
};

export const ListProvider = ({ children }: ListProps): JSX.Element => {
  const value = useList();
  return <ListContext.Provider value={value}>{children}</ListContext.Provider>;
};
