import { Fragment, useContext, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { EventPartContext } from './EventPartProvider';
import ImgUpload from './imgUpload';

const Image = (): JSX.Element => {
  const { eventRecord, updateRecord } = useContext(EventPartContext);
  const bucket = process.env.REACT_APP_IMAGE_BUCKET;
  const url =
    'https://' + bucket + '.s3.' + process.env.REACT_APP_REGION + '.amazonaws.com/public/';
  const [src, setSrc] = useState('');
  const reader = new FileReader();

  useEffect(() => {
    if (eventRecord) {
      setSrc(eventRecord.image);
    }
  }, [eventRecord]);

  const updateImg = (text: string) => {
    if (eventRecord && text !== src) {
      updateRecord('image', text);
      setSrc(text);
    }
  };

  reader.onload = (ev: ProgressEvent<FileReader>) => {
    ev.total;
  };

  if (src) {
    return (
      <Fragment>
        <Card.Img variant="top" src={url + src} />
        <ImgUpload update={updateImg} />
        {/* <InlineEdit text={src} onSetText={updateImg} /> */}
      </Fragment>
    );
  } else return <Fragment />;
};
export default Image;
