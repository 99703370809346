import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';
import { getCondition } from '../../../types/evnt';
import { EventPartContext } from './EventPartProvider';
import InlineEdit from './InlineEdit';

type Props = {
  id: string;
};

const ConditionCmpt = (props: Props): JSX.Element => {
  const { i18n } = useTranslation();
  const { event, update, remove } = useContext(EventPartContext);
  const [condition, setCondition] = useState('');

  useEffect(() => {
    if (event) {
      const condition = getCondition(event, props.id);
      if (condition) setCondition(condition.condition);
    }
  }, [event, i18n.language]);

  const updateCondition = (text: string) => {
    if (text !== condition) {
      setCondition('speichern...');
      update('Condition', props.id, 'condition', text);
    }
  };

  const deleteCondition = () => {
    remove('Condition', props.id);
  };

  return (
    <tr>
      <td>
        <InlineEdit text={condition} onSetText={updateCondition} />
      </td>
      <td>
        <FaTimes
          title="Delete event"
          className="text-danger float-end"
          size={21}
          onClick={deleteCondition}
        />
      </td>
    </tr>
  );
};
export default ConditionCmpt;
