import { configureStore } from '@reduxjs/toolkit';
import { api } from './services/Api_DynamoDB';
import  idToken from './slices/idTokenSlice'

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
     idToken
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}).concat(api.middleware),
  devTools: true
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
