import { Fragment, useContext, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { EventContext } from './EventProvider';
import { getEventRecord } from '../../../types/evnt';

const ImageCmpt = (): JSX.Element => {
  const { event } = useContext(EventContext);
  const bucket = process.env.REACT_APP_IMAGE_BUCKET;
  const url =
    'https://' + bucket + '.s3.' + process.env.REACT_APP_REGION + '.amazonaws.com/public/';
  const [src, setSrc] = useState('');

  useEffect(() => {
    if (event) {
      const ev = getEventRecord(event);
      if (ev) setSrc(url + ev.image);
    }
  }, [event]);

  if (src) {
    return <Card.Img variant="top" src={src} />;
  } else return <Fragment />;
};
export default ImageCmpt;
