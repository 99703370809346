import { useTranslation } from "react-i18next";
import { useContext, useState, useEffect } from "react";
import { EventContext } from "./EventProvider";
import { getEventRecord } from "../../../types/evnt";
import { useNavigate } from "react-router-dom";

const Registration = (): JSX.Element => {
  const { event } = useContext(EventContext);
  const { t } = useTranslation();
  const navi = useNavigate();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (event) {
      const rec = getEventRecord(event);
      if (rec) setOpen(rec.registration_open ? true : false); // bool conversion because of strange error message!
    }
  }, [event]);

  const register = () => {
    if (event) navi("/events/" + event.id + "/registration");
  };

  return open ? (
    <div className="register">
      <a className="btn btn-warning btn-lg mt-3 mb-3" onClick={register}>
        {t("Registration")}
      </a>
    </div>
  ) : (
    <div></div>
  );
};
export default Registration;
