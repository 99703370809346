import Cards from "./Cards";
import { ListProvider } from "./ListProvider";

const Index = (): JSX.Element => {
  return (
    <ListProvider>
      <Cards />
    </ListProvider>
  );
};
export default Index;
