import _ from 'lodash';
import moment from 'moment';
import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getEventRecord } from '../../../types/evnt';
import { EventContext } from './EventProvider';

type PriceProps = {
  title: string;
};

const Price = (props: PriceProps): JSX.Element => {
  const { t } = useTranslation();
  const { event } = useContext(EventContext);

  const [until, setUntil] = useState('');
  const [amount, setAmount] = useState('');
  const [slide, setSlide] = useState(false);
  const [range1, setRange1] = useState('');
  const [range2, setRange2] = useState('');

  useEffect(() => {
    if (event) {
      const rec = getEventRecord(event);
      if (rec) {
        const tarif = _(rec.tarifs).find((x) => x.id == props.title);
        if (tarif) {
          setUntil(moment(tarif.untilDate).toDate().toLocaleDateString());
          setAmount(tarif.amount.toFixed(2));
          setSlide(tarif.slidingscale);
          setRange1(tarif.range1.toFixed(2));
          setRange2(tarif.range2.toFixed(2));
        }
      }
    }
  }, [event]);
  return (
    <tbody>
      <tr>
        <th>{t(props.title)}</th>
        <th>{props.title === 'earlybird' ? t('until') + ' ' + until : ''}</th>
      </tr>
      <tr>
        <td>{t('amountPrice')}</td>
        {!slide ? (
          <td>€ {amount}</td>
        ) : (
          <td>
            € {range1} - € {range2}
          </td>
        )}
      </tr>
    </tbody>
  );
};
export default Price;
