import { useState, useCallback, ChangeEvent } from 'react';

interface ctype {
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const useCheckbox = (): ctype => {
  const [checked, setChecked] = useState(false);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setChecked(e.currentTarget.checked),
    []
  );

  return {
    checked,
    onChange
  };
};

export default useCheckbox;
