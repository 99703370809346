import { Fragment, useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { EventPartContext } from './EventPartProvider';
import InlineEdit from './InlineEdit';

const LocationCmpt = (): JSX.Element => {
  const { eventRecord, updateRecord } = useContext(EventPartContext);
  const {t}=useTranslation();
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [href, setHref] = useState('');

  useEffect(() => {
    if (eventRecord) {
      setName(eventRecord.location);
      setAddress(eventRecord.address);
      setHref(eventRecord.href);
    }
  }, [eventRecord]);

  const updateName = (value: string) => {
    if (value !== name) {
      setName(value);
      updateRecord('location', value);
    }
  };

  const updateAddress = (value: string) => {
    if (value !== address) {
      setAddress(value);
      updateRecord('address', value);
    }
  };

  const updateHref = (value: string) => {
    if (value !== href) {
      setHref(value);
      updateRecord('href', value);
    }
  };

  return (
    <div className="location">
      <Table>
        <caption>
          <h4>{t('location')}</h4>
        </caption>
        <tbody>
          <tr>
            <td>
              <Fragment>
                <b>
                  <InlineEdit text={name} onSetText={updateName} />
                </b>
                <div>
                  <InlineEdit text={address} onSetText={updateAddress} />
                </div>
                <div>
                  <InlineEdit text={href} onSetText={updateHref} />
                </div>
              </Fragment>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default LocationCmpt;
