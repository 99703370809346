import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDate, getDateTranslation } from '../../../types/evnt';
import { EventContext } from './EventProvider';

type Props = {
  id: string;
};

const DateCmpt = (props: Props): JSX.Element => {
  const { i18n } = useTranslation();
  const { event } = useContext(EventContext);
  const [caption, setCaption] = useState('');
  const [comment, setComment] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');

  useEffect(() => {
    if (event) {
      const dt = getDate(event, props.id);
      if (dt) {
        setFrom(dt.from);
        setTo(dt.to);
      }
      const dtt = getDateTranslation(event, props.id, i18n.language);
      if (dtt) {
        setCaption(dtt.caption);
        setComment(dtt.comment);
      }
    }
  }, [event, i18n.language]);

  return (
    <tr>
      <td>
        <div>
          <b>{caption}</b>
        </div>
        <div className="mt-1 mb-1">
          {moment(from).toDate().toLocaleDateString()} - {moment(to).toDate().toLocaleDateString()}
        </div>
        <div>{comment}</div>
      </td>
    </tr>
  );
};

export default DateCmpt;
