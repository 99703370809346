import _ from "lodash";
import moment from "moment";
import { ChangeEvent, useState, useContext } from "react";
import { Alert, Button, Form, Modal, InputGroup } from "react-bootstrap";
import DatePicker from "react-date-picker";
import { useTranslation } from "react-i18next";
import { BsCalendar } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import { EventItem } from "../../../types/evnt";
import { EventPartContext } from "../Editor/EventPartProvider";

interface Props {
  eventId: string;
  regId: string;
  fullamount: number;
  onSave: (payment: EventItem) => void;
}

const CreatePayment = (props: Props): JSX.Element => {
  const [show, setShow] = useState(false);
  const [amount, setAmount] = useState(props.fullamount);

  const [pdate, setPdate] = useState(new Date());
  const [mode, setMode] = useState("Konto");
  const [ref, setRef] = useState("");
  const [error, setError] = useState("");

  const { create } = useContext(EventPartContext);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const { t, i18n } = useTranslation();

  const Save = () => {
    create("Payment", {
      regId: props.regId,
      date: moment(pdate).format("yyy-MM-DD"),
      betrag: amount,
      zahlungsart: mode,
      nummer: ref,
    });
    setShow(false);
  };

  return (
    <>
      <a
        className="float-end text-success"
        title={t("Add payment")}
        onClick={(e) => {
          handleShow();
          e.preventDefault();
          return false;
        }}
      >
        <FaPlus size={21} />
      </a>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("Payment")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            {error === "" ? (
              <Form.Label className="me-3">{t("amountPrice")}</Form.Label>
            ) : (
              <Form.Label className="me-3 text-danger">
                {t("amountPrice")}
              </Form.Label>
            )}
            <InputGroup className="mb-3">
              <input
                type={"number"}
                value={amount.toFixed(2)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const b = _.toNumber(_.replace(e.target.value, ",", "."));
                  if (!_.isNaN(b)) {
                    setAmount(b);
                    setError("");
                  } else {
                    setError(e.target.value + " ist kein gültiger Betrag");
                  }
                }}
              ></input>{" "}
              <InputGroup.Text>€</InputGroup.Text>
            </InputGroup>
            {/* <InlineEdit
              text={amount.toFixed(2)}
              onSetText={(text: string) => {
                const b = _.toNumber(_.replace(text, ",", "."));
                if (!_.isNaN(b)) {
                  setAmount(b);
                  setError("");
                } else {
                  setError(text + " ist kein gültiger Betrag");
                }
              }}
            /> */}

            <Form.Label className="me-3">{_.capitalize(t("date"))}:</Form.Label>
            <br />
            <DatePicker
              value={pdate}
              onChange={(date: Date) => {
                setPdate(date);
              }}
              calendarIcon={<BsCalendar />}
              clearIcon={null}
              locale={i18n.language}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Art:</Form.Label>
            <Form.Select
              value={mode}
              onChange={(val: ChangeEvent<HTMLSelectElement>) => {
                setMode(val.target.value);
              }}
            >
              <option value="Konto">Konto</option>
              <option value="Bar">Bar</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="me-3">{t("Reference")}:</Form.Label>
            <input
              type="text"
              className="w-100"
              value={ref}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setRef(e.target.value);
              }}
            ></input>
          </Form.Group>
          {error !== "" ? (
            <Alert
              variant="danger"
              className="mt-4"
              onClose={() => setError("")}
              dismissible
            >
              <p> {t(error)}</p>
            </Alert>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="ps-4 pe-4" onClick={Save}>
            {t("Ok")}
          </Button>{" "}
          <Button variant="secondary" onClick={handleClose}>
            {t("Cancel")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default CreatePayment;
