import { useContext, useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getTranslation } from '../../../types/evnt';
import { EventContext } from './EventProvider';

const Description = (): JSX.Element => {
  const { i18n } = useTranslation();
  const { event } = useContext(EventContext);
  const [descr, setDescr] = useState('');

  useEffect(() => {
    if (event) {
      const tx = getTranslation(event, i18n.language);
      if (tx) {
        setDescr(tx.description);
      }
    }
  }, [event, i18n.language]);

  return (
    <Card.Text
      dangerouslySetInnerHTML={{
        __html: descr
      }}
    />
  );
};

export default Description;
