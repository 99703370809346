import _ from "lodash";
import { Dropdown, Row, Table } from "react-bootstrap";
import { Fragment, useContext } from "react";
import Spin from "../../Diverse/Spin";
import { AListContext } from "./ListProvider";
import Entry from "./Entry";
import { useNavigate, useParams } from "react-router-dom";

const List = (): JSX.Element => {
  const { year } = useParams();
  const { isLoading, list } = useContext(AListContext);
  const navi = useNavigate();
  return (
    <Fragment>
      {isLoading ? (
        <Spin />
      ) : (
        <Row>
          <Table hover>
            <caption>
              <h3 className="float-start">Archiv {year}</h3>
              <Dropdown className="float-end">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  Jahr
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      navi("/archive/2022");
                    }}
                  >
                    2022
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      navi("/archive/2021");
                    }}
                  >
                    2021
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      navi("/archive/2020");
                    }}
                  >
                    2020
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      navi("/archive/2019");
                    }}
                  >
                    2019
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      navi("/archive/2018");
                    }}
                  >
                    2018
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      navi("/archive/2017");
                    }}
                  >
                    2017
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      navi("/archive/2016");
                    }}
                  >
                    2016
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      navi("/archive/2015");
                    }}
                  >
                    2015
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </caption>
            <tbody>
              {_.map(list, (event, idx) => {
                return <Entry idx={idx} key={event.id} />;
              })}
            </tbody>
          </Table>
        </Row>
      )}
    </Fragment>
  );
};

export default List;
