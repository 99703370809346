import React, { useEffect, useState } from "react";
import {
  useCreatePageMutation,
  useDeletePageMutation,
  useGetPagesQuery,
  useUpdatePageMutation,
} from "../services/Api_DynamoDB";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { Page } from "../types/Pages";
import _ from "lodash";
import { useTranslation } from "react-i18next";

interface PagesState {
  pages: Page[] | undefined;
  isLoading: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  savePage: (item: Page) => void;
  deletePage: (id: string) => void;
}

export const PagesContext = React.createContext<PagesState>({
  pages: undefined,
  isLoading: false,
  error: undefined,
  savePage: () => {},
  deletePage: () => {},
});

interface EventProps {
  children: React.ReactNode;
}

export const usePages = (): PagesState => {
  const { data, error, isLoading } = useGetPagesQuery("");
  const [allpages, setAllpages] = useState<Page[]>([]);
  const [pages, setPages] = useState<Page[]>([]);
  const [update, updateResult] = useUpdatePageMutation();
  const [create, createResult] = useCreatePageMutation();
  const [deletePge, deleteResult] = useDeletePageMutation();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (data) {
      setAllpages(data.Items);
    }
  }, [data]);

  useEffect(() => {
    if (allpages) {
      setPages(
        _(allpages)
          .filter({ language: i18n.language })
          .sortBy((x) => x.index)
          .value()
      );
    }
  }, [allpages, i18n.language]);

  const savePage = (item: Page) => {
    if (item.id) {
      update(item);
    } else {
      create(_.omit(item, "id"));
    }
  };
  const deletePage = (id: string) => {
    deletePge(id);
  };

  useEffect(() => {
    if (updateResult && updateResult.data && pages) {
      const updated = updateResult.data;
      const update = allpages.map((tmp) =>
        tmp.id === updated.id ? updated : tmp
      );
      setAllpages(update);
    }
  }, [updateResult]);

  useEffect(() => {
    if (createResult && createResult.data) {
      setAllpages([...allpages, createResult.data]);
    }
  }, [createResult]);

  useEffect(() => {
    if (deleteResult && deleteResult.data) {
      setAllpages(_(allpages).reject({ id: deleteResult.data.id }).value());
    }
  }, [deleteResult]);

  return { pages, isLoading, error, savePage, deletePage };
};

export const PagesProvider = ({ children }: EventProps): JSX.Element => {
  const evt = usePages();
  return <PagesContext.Provider value={evt}>{children}</PagesContext.Provider>;
};

export {};
