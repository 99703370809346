import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Price from "./price";

const PriceTable = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className="price">
      <Table>
        <caption>
          <h4>{t("price")} </h4>
        </caption>
        <Price name="earlybird" />
        <Price name="full" />
        <Price name="deposit" />
      </Table>
    </div>
  );
};

export default PriceTable;
