import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import i18n from '../../../i18n';
import { getSpecials, Special } from '../../../types/evnt';
import { EventPartContext } from './EventPartProvider';
import SpecialCmpt from './special';

const Specials = (): JSX.Element => {
  const { event, create } = useContext(EventPartContext);
  const [specials, setSpecials] = useState<Special[]>([]);
  const {t}=useTranslation();

  useEffect(() => {
    if (event) setSpecials(getSpecials(event, i18n.language));
  }, [event, i18n.language]);

  const addSpecial = () => {
    const language = i18n.language;
    create('Special', { language, special: 'special' });
  };

  return (
    <div className="specials">
      <Table>
        <caption>
          <h4>
            {t('specials')}
            <FaPlus
              title="Add special"
              className="text-success float-end"
              size={21}
              onClick={addSpecial}
            />
          </h4>
        </caption>
        <tbody>
          {_.map(specials, (special) => {
            if (special) return <SpecialCmpt key={special.id} id={special.id} />;
          })}
        </tbody>
      </Table>
    </div>
  );
};
export default Specials;
