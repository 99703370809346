import { useContext, useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { Payment, Registration } from "../../../types/Registration";
import { getPayments, getRegistration } from "../../../types/evnt";
import { useParams } from "react-router-dom";
import CreatePayment from "./CreatePayment";
import _ from "lodash";
import { FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import { EventPartContext } from "../Editor/EventPartProvider";

export const PaymentList = (): JSX.Element => {
  const { event, remove, update } = useContext(EventPartContext);
  const [reg, setReg] = useState<Registration>();
  const [payments, setPayments] = useState<Payment[]>([]);
  const { regid } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (event && regid) {
      const payments = getPayments(event, regid);
      setPayments(payments);
      const payed = _(payments).sumBy((p) => p.betrag);
      const reg = getRegistration(event, regid);
      if (reg) {
        setReg(reg);
        if (reg.bezahlt != payed) {
          setReg({ ...reg, bezahlt: payed });
          update("Registration", regid, "bezahlt", payed);
        }

        if (reg.offen != reg.soll - payed) {
          setReg({ ...reg, offen: reg.soll - payed });
          update("Registration", regid, "offen", reg.soll - payed);
        }
      }
    }
  }, [event]);

  const deletePayment = (id: string) => {
    if (event) {
      remove("Payment", id);
    }
  };
  if (reg)
    return (
      <Card>
        <Card.Header>
          <h3>{t("Payments")}</h3>
        </Card.Header>
        <Card.Body>
          <Table hover>
            <caption></caption>
            <thead>
              <tr>
                <th>{reg.earlybird ? t("earlybird") : t("full")}</th>
                <th></th>
                <th>
                  <span className="float-end">{reg.soll.toFixed(2)}€</span>
                </th>
                <th>
                  <CreatePayment
                    eventId={reg.event}
                    regId={reg.id}
                    fullamount={reg.soll}
                    onSave={(item) => {
                      setPayments([...payments, item as Payment]);
                    }}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {_(payments)
                .map((pay) => {
                  return (
                    <tr key={pay.id}>
                      <td>{pay.date}</td>
                      <td>{pay.zahlungsart}</td>

                      <td>
                        <span className="float-end">
                          {pay.betrag.toFixed(2)}€
                        </span>{" "}
                      </td>
                      <td>
                        <FaTimes
                          title={t("Delete payment")}
                          className="text-danger float-end"
                          size={21}
                          onClick={() => deletePayment(pay.id)}
                        />
                      </td>
                    </tr>
                  );
                })
                .value()}
              <tr>
                <td></td>
                <td>{t("Sum")}:</td>
                <td>
                  <span className="float-end text-success">
                    {_(payments)
                      .sumBy((p) => p.betrag)
                      .toFixed(2)}
                    €
                  </span>
                </td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td>{t("Open")}:</td>
                <td>
                  <span className="float-end text-danger">
                    {(reg.soll - _(payments).sumBy((x) => x.betrag)).toFixed(2)}
                    €
                  </span>
                </td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    );
  return <></>;
};
