import { useCallback, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import useKeyPress from "../../../hooks/useKeyPress";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

export const TextInput = (props: {
  label: string;
  txt: string;
  onChange: (txt: string) => void;
}): JSX.Element => {
  const [text, setText] = useState("");
  const ref = useRef<HTMLTextAreaElement>();
  const [isActive, setIsActive] = useState(false);
  const esc = useKeyPress("Escape");

  // check to see if the user clicked outside of this component
  useOnClickOutside(ref, () => {
    if (isActive) {
      setIsActive(false);
      if (text !== props.txt) props.onChange(text);
    }
  });

  const onEsc = useCallback(() => {
    if (esc) {
      setText(props.txt);
    }
  }, [esc, props.txt]);

  useEffect(() => {
    onEsc();
  }, [onEsc]);

  useEffect(() => {
    setText(props.txt);
  }, [props.txt]);

  return (
    <Form.Control
      type="text"
      // eslint-disable-next-line
      ref={ref as any}
      placeholder={"enter " + props.label}
      style={{
        fontFamily: "Hack, monospace",
        fontSize: "9pt",
        whiteSpace: "nowrap",
        tabSize: 2,
      }}
      value={text}
      onChange={(e) => {
        setText(e.target.value);
      }}
      onSelect={() => {
        setIsActive(true);
      }}
    />
  );
};
