import { ListProvider } from './ListProvider';
import List from './List';

const BackList = (): JSX.Element => {
  //const [isArchived, setIsArchived] = useState(false)
  // const [searchParams, setSearchParams] = useSearchParams();
  // const isArchived =  searchParams.get('archived')?.toLowerCase() == "true";
  return (
    <ListProvider>
      <List></List>
    </ListProvider>
  );
};

export default BackList;
