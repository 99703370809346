import { useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getRegistration } from "../../../types/evnt";
import { Registration } from "../../../types/Registration";
import { EventContext } from "../Editor/EventProvider";

export const Message = (props: { id: string }): JSX.Element => {
  const { event } = useContext(EventContext);
  const [reg, setReg] = useState<Registration>();
  const { t } = useTranslation();

  useEffect(() => {
    if (event) {
      const reg = getRegistration(event, props.id);
      if (reg) setReg(reg);
    }
  }, [event]);
  if (reg)
    return (
      <Card>
        <Card.Header>{t("Message")}</Card.Header>
        <Card.Body>{reg.msg}</Card.Body>
      </Card>
    );
  else return <></>;
};
