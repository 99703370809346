import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Frontpage from "./Frontpage";
import skulptur from "../../../assets/images/elsdance/Skulptur700.jpg";

const TopRow = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Row className="home">
      <div className="d-xs-none d-sm-block col-sm-6 d-md-none d-lg-block col-lg-3 col-xl-15">
        <div className="row row11">
          <div className="d-sm-none d-sm-block d-md-none d-lg-block col-sm-12">
            <div
              id="topleft"
              className="col-sm-12 sq-sm-15 sq-md-3 sq-lg-15 sq-xl-15"
            ></div>
          </div>
        </div>
        <div className="d-xs-none d-lg-block row  row11">
          <Container>
            <Row>
              <Col>
                <div
                  id="news"
                  className="col-sm-12 sq-sm-15 sq-md-3 sq-lg-15 sq-xl-15"
                >
                  <div style={{ padding: "10px" }}>
                    <Frontpage />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="col-sm-6 col-md-4 col-lg-3 col-xl-25">
        <div id="content" className="sq-sm-8 sq-md-6 sq-lg-25">
          <div className="d-sm-block d-sm-none pb-3">
            <img src={skulptur} className="img-fluid" alt="Responsive image" />
          </div>
          <h4>Elske Seidel </h4>
          {/* <Trans i18nKey="portrait" t={t} /> */}
          <div className="mb-2">{t("portrait1")}</div>
          <div className="mb-2">{t("portrait2")}</div>
          <div className="fst-italic">{t("portrait3")}</div>
        </div>
      </div>
      <div className="col-sm-12 col-md-8 col-lg-6 col-xl-25">
        <div className="row row11">
          <div className="col-sm-12">
            <div
              id="bio"
              className="bio col-sm-12 sq-sm-8 sq-md-6 sq-lg-25"
            ></div>
          </div>
        </div>
      </div>
    </Row>
  );
};

export default TopRow;
