import _ from "lodash";
import { Row, Table } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import Current from "./Entry";
import { Fragment, useContext } from "react";
import Spin from "../../Diverse/Spin";
import { ListContext } from "./ListProvider";

const List = (): JSX.Element => {
  const { isLoading, list, addEvent } = useContext(ListContext);

  return (
    <Fragment>
      {isLoading ? (
        <Spin />
      ) : (
        <Row>
          <Table hover>
            <caption>
              <h3 className="float-start">Events</h3>
              <a className="float-end" onClick={addEvent} title="New event">
                <FaPlus size={21} />
              </a>
            </caption>
            <tbody>
              {_.map(list, (event, idx) => {
                return <Current idx={idx} key={event.id} id={event.id} />;
              })}
            </tbody>
          </Table>
        </Row>
      )}
    </Fragment>
  );
};

export default List;
