import _ from "lodash";
import {
  useDeleteMutation,
  useGetByYearQuery,
  useUpdateFieldPartMutation,
} from "../../../services/Api_DynamoDB";
import {
  useState,
  useEffect,
  createContext,
  Dispatch,
  SetStateAction,
} from "react";
import { Event } from "../../../types/evnt";
import { useParams } from "react-router-dom";
import moment from "moment";

interface ListProps {
  children: React.ReactNode;
}

interface ListState {
  isLoading: boolean;
  list: Event[];
  setList: Dispatch<SetStateAction<Event[]>>;
  restoreFromArchive: (idx: number) => void;
  deleteArchived: (idx: number) => void;
}

export const AListContext = createContext<ListState>({
  isLoading: false,
  list: [],
  setList: () => {},
  restoreFromArchive: () => {},
  deleteArchived: () => {},
});

const useList = (): ListState => {
  const { year } = useParams();
  const { data = [], isLoading } = useGetByYearQuery({
    tag: "archive",
    year: year ? year : moment().format("YYYY"),
  });

  const [updateTrigger] = useUpdateFieldPartMutation();
  const [useDeleteArchivedTrigger] = useDeleteMutation();
  const [list, setList] = useState<Event[]>([]);

  useEffect(() => {
    if (data.length > 0) setList(data);
  }, [data]);

  const deleteArchived = (idx: number) => {
    useDeleteArchivedTrigger(list[idx].id).then(() => {
      setList(_.filter(list, (_, i) => i !== idx));
    });
  };

  const restoreFromArchive = (idx: number) => {
    if (list.length > idx) {
      const event = list[idx];
      _(event.Items).forEach((x) => {
        updateTrigger({
          id: event.id,
          typeName: x.typeName,
          key: x.id,
          body: { field: "tag", value: "active" },
        });
      });
      setList(_.filter(list, (_, i) => i !== idx));
    }
  };

  return { isLoading, list, setList, deleteArchived, restoreFromArchive };
};

export const ListProvider = ({ children }: ListProps): JSX.Element => {
  const value = useList();

  return (
    <AListContext.Provider value={value}>{children}</AListContext.Provider>
  );
};
