import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTranslation } from '../../../types/evnt';
import { EventContext } from './EventProvider';

const Title = (): JSX.Element => {
  const { i18n } = useTranslation();
  const { event } = useContext(EventContext);

  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');

  useEffect(() => {
    if (event) {
      const tx = getTranslation(event, i18n.language);
      if (tx) {
        setTitle(tx.title);
        setSubtitle(tx.subtitle);
      }
    }
  }, [event, i18n.language]);

  return (
    <div>
      <h1> {title} </h1>
      <h6 className="text-muted">{subtitle}</h6>
    </div>
  );
};

export default Title;
