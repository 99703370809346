import { useTranslation } from "react-i18next";
import { useGetByTagQuery } from "../../../services/Api_DynamoDB";
import { FaChevronRight } from "react-icons/fa";
import _ from "lodash";
import { GiSpinalCoil } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { getEventRecord, getTranslation } from "../../../types/evnt";
import moment from "moment";

const Frontpage = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const navi = useNavigate();
  const { data, error, isLoading } = useGetByTagQuery("active");
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [start, setStart] = useState("");
  const [id, setId] = useState("");

  useEffect(() => {
    if (data && data.length > 0) {
      const frontpage = _(data).find((x) => {
        const rec = getEventRecord(x);
        if (rec) return rec.frontpage;
        else return false;
      });
      if (frontpage) {
        setId(frontpage.id);

        const tx = getTranslation(frontpage, i18n.language);
        if (tx) {
          setTitle(tx.title);
          setSubtitle(tx.subtitle);
        }
        const event = getEventRecord(frontpage);
        if (event) {
          setStart(moment(event.start).toDate().toLocaleDateString());
        }
      }
    }
  }, [data, i18n.language]);

  return isLoading ? (
    <div>
      <GiSpinalCoil
        size={28}
        className=" icon-spin position-absolute top-50 start-50 translate-middle"
      />
    </div>
  ) : error ? (
    <div>
      <h4>Fehler</h4>
      {"status" in error ? error.status : ""}
    </div>
  ) : data ? (
    <div>
      <div className="mb-4">
        <a className="stretched-link" onClick={() => navi("/Events/" + id)}>
          <h6>
            <small>{t("Current")}:&nbsp;</small>
            {start}
          </h6>
          <h5>{title}</h5>
          {subtitle}
        </a>
      </div>
      <div>
        <a className="stretched-link " onClick={() => navi("/events")}>
          {t("more Events")}
          <FaChevronRight />
        </a>
      </div>
    </div>
  ) : (
    <div>No frontpage event set.</div>
  );
};
export default Frontpage;
