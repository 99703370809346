import "./App.css";
import Home from "./components/Frontend/Home";
import NavBar from "./components/NavBar";
import Container from "react-bootstrap/esm/Container";
import { Provider } from "react-redux";
import store from "./store";
import List from "./components/Frontend/List";
import Event from "./components/Frontend/Event";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Registration from "./components/Frontend/Registration";
import Conditions from "./components/Frontend/Registration/conditions";
import Finish from "./components/Frontend/Registration/Finish";
import BackList from "./components/Backend/List/Index";
import Editor from "./components/Backend/Editor";
import { AuthProvider } from "./components/Auth/useAuth";
import RequireAuth from "./components/Auth/RequireAuth";
import ChangePassword from "./components/Auth/ChangePassword";
import Archive from "./components/Backend/Archive/Index";
import Templates from "./components/Backend/Templates/Index";
import RegList from "./components/Backend/Registration/Index";
import { RegistrationAdmin } from "./components/Backend/Registration/RegistrationAdmin";
import { EventProvider } from "./components/Backend/Editor/EventProvider";
import Login from "./components/Auth/Login";
import MailTemplates from "./components/Backend/Mailtemplates";
import { EventPartProvider } from "./components/Backend/Editor/EventPartProvider";
import { PagesProvider } from "./components/PageProvider";

import { CustomPage } from "./components/CustomPage";
import { Custompages } from "./components/Backend/Custompages";
import { MailsProvider } from "./components/Backend/Mailtemplates/MailsProvider";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const App = (): JSX.Element => {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (i18n.language)
      document.title = t("pagetitle", { ns: process.env.REACT_APP_APPID });
  }, [i18n.language]);

  return (
    <Provider store={store}>
      <AuthProvider>
        <PagesProvider>
          <Container>
            <BrowserRouter>
              <NavBar />
              <Routes>
                {process.env.REACT_APP_APPID == "elskedance" ? (
                  <Route path="/" element={<Home />} />
                ) : (
                  <Route path="/" element={<CustomPage />} />
                )}

                {process.env.REACT_APP_APPID == "elskedance" ? (
                  <Route index element={<Home />} />
                ) : (
                  <Route index element={<CustomPage />} />
                )}

                <Route path="events/:eventid" element={<Event />} />
                <Route
                  path="events/:eventid/registration"
                  element={<Registration />}
                />
                <Route
                  path="events/:eventid/registration/conditions"
                  element={
                    <MailsProvider>
                      <Conditions />
                    </MailsProvider>
                  }
                />
                <Route
                  path="events/:eventid/registration/conditions/finish"
                  element={<Finish />}
                />
                <Route path="events" element={<List />} />
                <Route path="login" element={<Login />} />
                <Route path="changePassword" element={<ChangePassword />} />
                <Route path="page/:name" element={<CustomPage />} />

                <Route
                  path="/backend"
                  element={
                    <RequireAuth>
                      <BackList />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/registrations/:eventid"
                  element={
                    <RequireAuth>
                      <RegList />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/registrations/:eventid/:regid"
                  element={
                    <RequireAuth>
                      <EventProvider>
                        <EventPartProvider>
                          <MailsProvider>
                            <RegistrationAdmin />
                          </MailsProvider>
                        </EventPartProvider>
                      </EventProvider>
                    </RequireAuth>
                  }
                />
                <Route
                  path="/backend/:eventid/editor"
                  element={
                    <RequireAuth>
                      <Editor />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/archive/:year"
                  element={
                    <RequireAuth>
                      <Archive />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/templates"
                  element={
                    <RequireAuth>
                      <Templates />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/mailtemplates"
                  element={
                    <RequireAuth>
                      <MailTemplates />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/custompages"
                  element={
                    <RequireAuth>
                      <Custompages />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/custompages/:name"
                  element={
                    <RequireAuth>
                      <Custompages />
                    </RequireAuth>
                  }
                />
              </Routes>
            </BrowserRouter>
          </Container>
        </PagesProvider>
      </AuthProvider>
    </Provider>
  );
};

export default App;
