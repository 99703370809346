import { useContext, useState, useEffect } from "react";
import { Card, Form } from "react-bootstrap";
import { getRegistration } from "../../../types/evnt";
import { Registration } from "../../../types/Registration";
import { EventPartContext } from "../Editor/EventPartProvider";

export const Status = (props: { id: string }): JSX.Element => {
  const { event, update } = useContext(EventPartContext);
  const [reg, setReg] = useState<Registration>();

  useEffect(() => {
    if (event) {
      const reg = getRegistration(event, props.id);
      if (reg) setReg(reg);
    }
  }, [event]);

  return (
    <>
      {reg ? (
        <Card>
          <Card.Header>
            <h4>Status</h4>{" "}
          </Card.Header>
          <Card.Body>
            <Form.Select
              onChange={(e) => {
                update("Registration", props.id, "status", e.target.value);
                setReg({ ...reg, status: e.target.value });
              }}
              aria-label="Default select example"
              value={reg.status}
            >
              <option>Open this select menu</option>
              <option>Neu</option>
              <option>Anm. bestätigt</option>
              <option>Angezahlt</option>
              <option>Ok</option>
              <option>RZ bar</option>
              <option>bar</option>
              <option>Storno</option>
              <option>Storno ok</option>
            </Form.Select>
          </Card.Body>
        </Card>
      ) : (
        ""
      )}
    </>
  );
};
