import _ from 'lodash';
import { useContext, useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getLinks, Link } from '../../../types/evnt';
import { EventContext } from './EventProvider';

const Links = (): JSX.Element => {
  const { event } = useContext(EventContext);
  const [records, setRecords] = useState<Link[]>([]);
  const {t}=useTranslation();
  useEffect(() => {
    if (event) setRecords(getLinks(event));
  }, [event]);

  return (
    <div className="links">
      {
        <Table>
          <caption>
            <h4>{t('links')}</h4>
          </caption>
          <tbody>
            {_.map(records, (rec) => {
              if (rec)
                return (
                  <tr key={rec.id}>
                    <td>
                      <a className="link-primary" target="_blank" rel="noreferrer" href={rec.href}>
                        {rec.title}
                      </a>
                    </td>
                  </tr>
                );
            })}
          </tbody>
        </Table>
      }
    </div>
  );
};

export default Links;
