import { ListProvider } from './ListProvider';
import List from './List';

const BackList = (): JSX.Element => {
  return (
    <ListProvider>
      <List></List>
    </ListProvider>
  );
};

export default BackList;
