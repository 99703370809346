import _ from "lodash";
import { Row, Table } from "react-bootstrap";
import { Fragment, useContext, useEffect, useState } from "react";
import Spin from "../../Diverse/Spin";
import Entry from "./Entry";
import { EventContext } from "../Editor/EventProvider";
import { Registration, Payment } from "../../../types/Registration";
import { getPayments, getRegistrations } from "../../../types/evnt";
import { CSVLink } from "react-csv";
import moment from "moment";

const List = (): JSX.Element => {
  const { isLoading, event } = useContext(EventContext);
  const [regs, setRegs] = useState<Registration[]>([]);

  useEffect(() => {
    if (event) {
      const r = getRegistrations(event);
      setRegs(r);
    }
  }, [event]);

  const headers = [
    { label: "Vorname", key: "vorname" },
    { label: "Nachname", key: "nachname" },
    { label: "Email", key: "email" },
    { label: "Telefon", key: "telefon" },
    { label: "Strasse", key: "adresse.strasse" },
    { label: "Hausnummer", key: "adresse.nr" },
    { label: "PLZ", key: "adresse.plz" },
    { label: "Ort", key: "adresse.ort" },
    { label: "Anmeldedatum", key: "anmeldedatum" },
    { label: "Früh", key: "frueh" },
    { label: "Status", key: "status" },
    { label: "Soll", key: "soll" },
    { label: "Bezahlt", key: "bezahlt" },
    { label: "Offen", key: "offen" },
    { label: "Zahlungsdatum", key: "payment.date" },
    { label: "Zahlungsbetrag", key: "payment.betrag" },
    { label: "Zahlungsart", key: "payment.zahlungsart" },
    { label: "Anmerkung", key: "msg" },
  ];
  type csvType =
    | (Registration & { frueh: string; anmeldedatum: string })
    | { payment: Payment };

  const AddPayments = (): csvType[] => {
    let lines: csvType[] = [];
    _(regs).forEach((reg) => {
      lines = _.concat(lines, {
        ...reg,
        telefon: "Tel: " + reg.telefon,
        anmeldedatum: moment(reg.date).format("YYYY-MM-DD"),
        frueh: reg.earlybird ? "Ja" : "Nein",
      });
      if (event) {
        const paymts = getPayments(event, reg.id);
        _(paymts).forEach((paym) => {
          lines = _.concat(lines, { payment: paym });
        });
      }
    });

    return lines;
  };

  return (
    <Fragment>
      {isLoading ? (
        <Spin />
      ) : (
        <Row>
          <Table hover>
            <caption>
              <h3 className="float-start">Anmeldungen</h3>
              <div className="float-end">
                <CSVLink
                  filename={"anmeldungen.csv"}
                  className="btn btn-primary"
                  headers={headers}
                  data={AddPayments()}
                  separator={";"}
                >
                  Download
                </CSVLink>
              </div>
            </caption>
            <thead>
              <tr>
                <th>Datum</th>
                <th>Früh</th>
                <th>Status</th>
                <th>Name</th>
                <th>Adresse</th>
                <th>Message</th>
                <th className="float-end me-2">Zahlung</th>
                <th>Offen</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {_.map(regs, (reg) => {
                return <Entry key={reg.id} id={reg.id} />;
              })}
            </tbody>
          </Table>
        </Row>
      )}
    </Fragment>
  );
};

export default List;
