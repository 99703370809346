import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import InlineTextArea from './InlineTextArea';
import { getTranslation } from '../../../types/evnt';
import { EventPartContext } from './EventPartProvider';

const Description = (): JSX.Element => {
  const { i18n } = useTranslation();
  const [descr, setDescr] = useState('');
  const { event, update } = useContext(EventPartContext);

  useEffect(() => {
    if (event) {
      const translation = getTranslation(event, i18n.language);
      if (translation) setDescr(translation.description);
    }
  }, [event, i18n.language]);

  const updateDescr = (text: string) => {
    if (event && text !== descr) {
      const translation = getTranslation(event, i18n.language);
      if (translation) {
        update('Translation', translation.id, 'description', text);
        setDescr('<Spinner animation="border" />');
      }
    }
  };

  return (
    <Card.Text>
      <InlineTextArea text={descr} onSetText={updateDescr} />
    </Card.Text>
  );
};

export default Description;
