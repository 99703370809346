import _ from "lodash";
import {
  useCreateMutation,
  useGetByTagQuery,
  useUpdateFieldPartMutation,
} from "../../../services/Api_DynamoDB";
import {
  useState,
  useEffect,
  createContext,
  Dispatch,
  SetStateAction,
} from "react";
import { Event, EventItem } from "../../../types/evnt";
import moment from "moment";

interface ListProps {
  children: React.ReactNode;
}

interface ListState {
  isLoading: boolean;
  list: Event[] | null;
  setList: Dispatch<SetStateAction<Event[]>>;
  addEvent: () => void;
  sendToArchive: (idx: number) => void;
}

export const ListContext = createContext<ListState>({
  isLoading: false,
  list: [],
  setList: () => {},
  addEvent: () => {},
  sendToArchive: () => {},
});

const useList = (): ListState => {
  const { data = [], isLoading } = useGetByTagQuery("active");
  const [createEvent, createResult] = useCreateMutation();
  const [updateTrigger] = useUpdateFieldPartMutation();
  const [list, setList] = useState<Event[]>([]);

  useEffect(() => {
    if (data.length > 0) setList(data);
  }, [data]);

  useEffect(() => {
    if (createResult.data) {
      setList(_(list).concat(createResult.data).value());
    }
  }, [createResult.data]);

  // useEffect(() => {
  //   if (createTransResult.data) {
  //     console.log(createTransResult.data);
  //     setList(_(list.Items).concat(createTransResult.data).value());
  //   }
  // }, [createTransResult.data]);

  const addEvent = () => {
    createEvent([
      eventRecordTemplate(),
      TranlationTemplateDE(),
      TranlationTemplateEN(),
    ]);
  };

  const sendToArchive = (idx: number) => {
    if (list.length > idx) {
      const event = list[idx];
      _(event.Items).forEach((x) => {
        if ("tag" in x)
          updateTrigger({
            id: event.id,
            typeName: x.typeName,
            key: x.id,
            body: { field: "tag", value: "archive" },
          });
      });
      setList(_.filter(list, (_, i) => i !== idx));
    }
  };

  return { isLoading, list, setList, addEvent, sendToArchive };
};

export const ListProvider = ({ children }: ListProps): JSX.Element => {
  const list = useList();

  return <ListContext.Provider value={list}>{children}</ListContext.Provider>;
};

const eventRecordTemplate = (): EventItem => {
  return {
    typeName: "EventRecord",
    id: "",
    tag: "active",
    start: moment(Date.now()).format("YYYY-MM-DD"),
    visible: false,
    registration_open: false,
    frontpage: false,
    image: "forest.jpg",
    location: "location",
    latitude: 51.505,
    longitude: -0.09,
    address: "Adresse",
    href: "Link",
    eventtype: 1,
    tarifs: [
      {
        id: "earlybird",
        untilDate: moment(Date.now()).format("YYYY-MM-DD"),
        amount: 0.0,
        slidingscale: false,
        range1: 0,
        range2: 0,
      },
      {
        id: "deposit",
        untilDate: moment(Date.now()).format("YYYY-MM-DD"),
        amount: 0.0,
        slidingscale: false,
        range1: 0,
        range2: 0,
      },
      {
        id: "full",
        untilDate: moment(Date.now()).format("YYYY-MM-DD"),
        amount: 0.0,
        slidingscale: false,
        range1: 0,
        range2: 0,
      },
    ],
  };
};

const TranlationTemplateDE = (): EventItem => {
  return {
    id: "",
    typeName: "Translation",
    tag: "active",
    start: moment(Date.now()).format("YYYY-MM-DD"),
    language: "de",
    title: "Neuer Event",
    subtitle: "Untertitel",
    description: "Neue Beschreibung",
    transportation: "Anreise",
  };
};

const TranlationTemplateEN = (): EventItem => {
  return {
    id: "",
    typeName: "Translation",
    tag: "active",
    start: moment(Date.now()).format("YYYY-MM-DD"),
    language: "en",
    title: "New Event",
    subtitle: "New subtitle",
    description: "new description",
    transportation: "new transportation",
  };
};
