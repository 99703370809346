import _ from 'lodash';
import { useContext, useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getTeachers, Teacher } from '../../../types/evnt';
import { EventContext } from './EventProvider';

const Teachers = (): JSX.Element => {
  //const {t} = useTranslation('elskedance');
  //const {t} = useTranslation('svendandersen');
  const {t} = useTranslation();
  const { event } = useContext(EventContext);
  const [teachers, setTeachers] = useState<Teacher[]>([]);

  useEffect(() => {
    if (event) {
      const te = getTeachers(event);
      if (te) setTeachers(te);
    }
  }, [event]);

  return (
    <div className="teachers">
      <Table>
        <caption>
          <h4>{t('teachers', {ns:process.env.REACT_APP_APPID})}</h4>
        </caption>
        <tbody>
          {_.map(
            _(teachers)
              .sortBy((x) => x.id)
              .value(),
            (teacher) => {
              if (teacher)
                return (
                  <tr key={teacher.id}>
                    <td>{teacher.teacher}</td>
                  </tr>
                );
            }
          )}
        </tbody>
      </Table>
    </div>
  );
};
export default Teachers;
