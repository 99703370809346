import { useContext } from "react";
import Editor from "./Editor";
import { EventPartProvider } from "./EventPartProvider";
import { EventContext, EventProvider } from "./EventProvider";

const EventEditor = (): JSX.Element => {
  const { event } = useContext(EventContext);

  return (
    <EventProvider>
      <EventPartProvider>
        <Editor key={event ? event.id : ""} />
      </EventPartProvider>
    </EventProvider>
  );
};
export default EventEditor;
