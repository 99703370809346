import _ from "lodash";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaGlobe, FaPencilAlt, FaUser, FaBell } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Registration } from "../../../types/Registration";
import { ListContext } from "./ListProvider";
import {
  getEventRecord,
  getRegistrations,
  getTranslation,
} from "../../../types/evnt";
import { BsArchive } from "react-icons/bs";

type Props = {
  idx: number;
  id: string;
};

const Current = (props: Props): JSX.Element => {
  const navi = useNavigate();
  const { i18n } = useTranslation();
  const { list = [], sendToArchive } = useContext(ListContext);

  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [frontpage, setFrontpage] = useState(false);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [start, setStart] = useState("");
  const [newReg, setNewReg] = useState(false);
  const [regCount, setRegCount] = useState(0);

  useEffect(() => {
    if (list && list.length > 0) {
      const entity = _(list).find((x) => x.id == props.id);
      if (entity) {
        const event = getEventRecord(entity);
        if (event) {
          setOpen(event.registration_open);
          setVisible(event.visible);
          setFrontpage(event.frontpage);
          setStart(event.start);
        }
        const trans = getTranslation(entity, i18n.language);
        if (trans) {
          setTitle(trans.title);
          setSubtitle(trans.subtitle);
        }
        const regs = getRegistrations(entity);
        setRegCount(regs.length);
        setNewReg(hasNewReg(regs));
      }
    }
  }, [list, i18n.language]);

  const hasNewReg = (regs: (Registration | undefined)[]) => {
    const newReg = _(regs).find((x) => x?.status == "Neu");
    return newReg ? true : false;
  };

  const View = () => {
    navi("/events/" + props.id);
  };
  const Edit = () => {
    navi("/backend/" + props.id + "/editor");
  };
  const showRegs = () => {
    navi("/registrations/" + props.id);
  };

  const Remove = () => {
    sendToArchive(props.idx);
  };

  return (
    <tr
      className={
        visible && open
          ? "bg-success bg-opacity-10"
          : visible && !open
          ? "bg-warning bg-opacity-10"
          : ""
      }
    >
      <td>
        {visible ? (
          <h4>
            <FaGlobe className={frontpage ? "text-danger" : ""} size={21} />{" "}
          </h4>
        ) : (
          ""
        )}
      </td>
      <td onClick={View}>
        <h4>
          <div> {title} </div>
          <small> {subtitle}</small>
        </h4>
      </td>
      <td>{moment(start).toDate().toLocaleDateString()}</td>
      <td>
        <FaPencilAlt title="Edit event" size={21} onClick={Edit} />
      </td>
      <td>
        <FaUser title="Registrations" size={21} onClick={showRegs} />
      </td>
      <td>
        {newReg ? (
          <FaBell
            className="text-danger"
            title="New registration"
            size={21}
            onClick={showRegs}
          />
        ) : (
          ""
        )}
      </td>
      <td onClick={showRegs}>{regCount}</td>
      <td>
        <BsArchive
          title="Send to archive"
          className="text-success"
          size={21}
          onClick={Remove}
        />
      </td>
    </tr>
  );
};

export default Current;
