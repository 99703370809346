import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { DateRange, getDates } from '../../../types/evnt';
import { EventContext } from './EventProvider';
import DateCmpt from './date';
import { useTranslation } from 'react-i18next';

const Dates = (): JSX.Element => {
  const {t} = useTranslation();
  const { event } = useContext(EventContext);
  const [records, setRecords] = useState<DateRange[]>([]);
  useEffect(() => {
    if (event) setRecords(getDates(event));
  }, [event]);

  return (
    <div className="date">
      <Table>
        <caption>
          <h4>{t('date')}</h4>
        </caption>
        <tbody>
          {_(records)
            .sortBy((x) => x.id)
            .map((rec) => <DateCmpt key={rec.id} id={rec.id} />)
            .value()}
        </tbody>
      </Table>
    </div>
  );
};

export default Dates;
