import _ from "lodash";
import { Row, Table } from "react-bootstrap";
import { Fragment, useContext } from "react";
import Spin from "../../Diverse/Spin";
import { ListContext } from "./ListProvider";
import Entry from "./Entry";

const List = (): JSX.Element => {
  const { isLoading, list } = useContext(ListContext);

  return (
    <Fragment>
      {isLoading ? (
        <Spin />
      ) : (
        <Row>
          <Table hover>
            <caption>
              <h3 className="float-start">Templates</h3>
            </caption>
            <tbody>
              {_.map(list, (event, idx) => {
                return <Entry idx={idx} key={event.id} />;
              })}
            </tbody>
          </Table>
        </Row>
      )}
    </Fragment>
  );
};

export default List;
