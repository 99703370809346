import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Auth } from 'aws-amplify';
import { Event, EventItem } from '../types/evnt';
import { MailTemplate, MailTemplates } from '../types/MailTemplate';
import { Page, Pages } from '../types/Pages';

/* eslint-disable  @typescript-eslint/no-explicit-any */
// Define a service using a base URL and expected endpoints
export const api = createApi({
  baseQuery: fetchBaseQuery({
    //baseUrl:,
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: async (headers) => {
      try {
        const session = await Auth.currentSession();
        if (session) {
          const idToken = session.getIdToken();
          if (idToken) {
            const jwtToken = idToken.getJwtToken();
            if (jwtToken) {
              headers.set('Authorization', `${jwtToken}`);
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
      return headers;
    }
  }),

  endpoints: (builder) => ({
    //Category

    GetByTag: builder.query<Event[], string>({
      query: (tag) => `/tag/${tag}`
    }),

    GetByYear: builder.query<Event[], { tag: string; year: string }>({
      query: ({ tag, year }) => `/tag/${tag}/${year}`
    }),

    //Event
    Get: builder.query<Event, string>({
      query: (id) => `/pl/event/${id}`
    }),

    GetFrontend: builder.query<Event, string>({
      query: (id) => `/frontend/event/${id}`
    }),

    PostFrontend: builder.mutation<Event, { id: string; body: any }>({
      query({ id, body }) {
        return {
          url: `/frontend/event/${id}/Registration`,
          method: 'POST',
          body
        };
      }
    }),

    Create: builder.mutation<Event, EventItem[]>({
      query(body) {
        return {
          url: `/pl/event`,
          method: 'POST',
          body
        };
      }
    }),

    Delete: builder.mutation<any, string>({
      query(id) {
        return {
          url: `/pl/event/${id}`,
          method: 'DELETE'
        };
      }
    }),

    CreatePart: builder.mutation<EventItem, { id: string; typeName: string; body: any }>({
      query({ id, typeName, body }) {
        return {
          url: `/sl/event/${id}/${typeName}`,
          method: 'POST',
          body: body
        };
      }
    }),

    UpdateMain: builder.mutation<Event, Event>({
      query({ id, Items }) {
        return {
          url: `/pl/event/${id}`,
          method: 'PUT',
          body: Items
        };
      }
    }),

    UpdatePart: builder.mutation<
      EventItem,
      { id: string; typeName: string; key: string; body: any }
    >({
      query({ id, typeName, key, body }) {
        return {
          url: `/sl/event/${id}/${typeName}/${key}`,
          method: 'PUT',
          body: body
        };
      }
    }),

    UpdateFieldPart: builder.mutation<
      EventItem,
      { id: string; typeName: string; key: string; body: any }
    >({
      query({ id, typeName, key, body }) {
        return {
          url: `/sl/event/${id}/${typeName}/${key}`,
          method: 'PATCH',
          body: body
        };
      }
    }),

    DeletePart: builder.mutation<any, { id: string; typeName: string; key: string }>({
      query({ id, typeName, key }) {
        return {
          url: `/sl/event/${id}/${typeName}/${key}`,
          method: 'DELETE'
        };
      }
    }),

    SendMail: builder.mutation<any, string>({
      query(body) {
        return {
          url: `/mail/sendmail`,
          method: 'POST',
          body
        };
      }
    }),

    GetMailTemplates: builder.query<MailTemplates, string>({
      query: () => `/frontend/mailtemplates/0`
    }),

    UpdateMailTemplate: builder.mutation<MailTemplate, MailTemplate>({
      query(body) {
        return {
          url: `/sl/mailtemplates/0/mailtemplate/${body.id}`,
          method: 'PUT',
          body
        };
      }
    }),

    CreateMailTemplate: builder.mutation<MailTemplate, any>({
      query(body) {
        return {
          url: `/sl/mailtemplates/0/MailTemplate/`,
          method: 'POST',
          body
        };
      }
    }),

    DeleteMailTemplate: builder.mutation<{ typeName: string, id: string }, string>({
      query(key) {
        return {
          url: `/sl/mailtemplates/0/MailTemplate/${key}`,
          method: 'DELETE'
        };
      }
    }),

    GetPages: builder.query<Pages, string>({
      query: () => `/frontend/pages/pages`
    }),

    UpdatePage: builder.mutation<Page, Page>({
      query(body) {
        return {
          url: `/sl/pages/pages/Page/${body.id}`,
          method: 'PUT',
          body
        };
      }
    }),

    CreatePage: builder.mutation<Page, any>({
      query(body) {
        return {
          url: `/sl/pages/pages/Page/`,
          method: 'POST',
          body
        };
      }
    }),

    DeletePage: builder.mutation<{ typeName: string, id: string }, string>({
      query(key) {
        return {
          url: `/sl/pages/pages/Page/${key}`,
          method: 'DELETE'
        };
      }
    }),
  })
});

export const {
  //Tag
  useGetByTagQuery,
  useGetByYearQuery,

  //Event
  useGetQuery,
  useCreateMutation,
  useUpdateMainMutation,
  useDeleteMutation,

  //Part
  useCreatePartMutation,
  useUpdatePartMutation,
  useUpdateFieldPartMutation,
  useDeletePartMutation,

  useGetFrontendQuery,
  usePostFrontendMutation,
  useSendMailMutation,

  useGetMailTemplatesQuery,
  useUpdateMailTemplateMutation,
  useCreateMailTemplateMutation,
  useDeleteMailTemplateMutation,

  useGetPagesQuery,
  useUpdatePageMutation,
  useCreatePageMutation,
  useDeletePageMutation,


} = api;
