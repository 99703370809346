import { EventContext, EventProvider } from "./EventProvider";
import { useContext } from "react";
import EventCmpt from "./event";

const Event = (): JSX.Element => {
  const { event } = useContext(EventContext);

  return (
    <EventProvider>
      <EventCmpt key={event?.id} />
    </EventProvider>
  );
};

export default Event;
