import ObjectID from 'bson-objectid';
import _ from 'lodash';
import { Payment, Registration, Note } from './Registration';

export type Event = {
  id: string;
  Items: EventItem[];
};

export type EventItem =
  | EventRecord
  | Translation
  | DateRange
  | DateTranslation
  | Link
  | Special
  | Teacher
  | Condition
  | Registration
  | Payment
  | Note;

export const getEventRecord = (e: Event): EventRecord | undefined => {
  return _(e.Items)
    .filter((x) => x.typeName === 'EventRecord')
    .map((x) => (x.typeName === 'EventRecord' ? x : undefined))
    .first();
};

export const getTranslation = (e: Event, language: string): Translation | undefined => {
  const result = _(e.Items)
    .filter((x) => x.typeName === 'Translation')
    .map((x) => (x.typeName === 'Translation' ? x : undefined))
    .find((x) => (x ? x.language == language : false));
  return result;
};

export const getDates = (e: Event): DateRange[] => {
  let result: DateRange[] = [];
  _(e.Items)
    .filter((x) => x.typeName === 'DateRange')
    .forEach((x) => {
      if (x.typeName === 'DateRange') result = _.concat(result, x);
    });
  return result;
};

export const getDate = (e: Event, id: string): DateRange | undefined => {
  return _(e.Items)
    .filter((x) => x.typeName === 'DateRange')
    .map((x) => (x.typeName === 'DateRange' ? x : undefined))
    .find((x) => (x ? x.id == id : false));
};

export const getDateTranslation = (
  e: Event,
  dateRange: string,
  language: string
): DateTranslation | undefined => {
  return _(e.Items)
    .filter((x) => x.typeName === 'DateTranslation')
    .value()
    .map((x) => (x.typeName === 'DateTranslation' ? x : undefined))
    .find((x) => (x ? x.dateRange == dateRange && x.language == language : false));
};

export const getDateTranslations = (
  e: Event,
  dateRange: string
): (DateTranslation | undefined)[] => {
  return _(e.Items)
    .filter((x) => x.typeName === 'DateTranslation' && x.dateRange == dateRange)
    .value()
    .map((x) => (x.typeName === 'DateTranslation' ? x : undefined));
};

export const getLinks = (e: Event): Link[] => {
  let result: Link[] = [];
  _(e.Items)
    .filter((x) => x.typeName === 'Link')
    .forEach((x) => {
      if (x.typeName === 'Link') result = _.concat(result, x);
    });
  return result;
};

export const getLink = (e: Event, id: string): Link | undefined => {
  return _(e.Items)
    .filter((x) => x.typeName === 'Link')
    .map((x) => (x.typeName === 'Link' ? x : undefined))
    .find((x) => (x ? x.id == id : false));
};

export const getRegistrations = (e: Event): Registration[] => {
  let result: Registration[] = [];
  _(e.Items)
    .filter((x) => x.typeName === 'Registration')
    .forEach((x) => {
      if (x.typeName === 'Registration') result = _.concat(result, x);
    });
  return result;
};

export const getRegistration = (e: Event, id: string): Registration | undefined => {
  return _(e.Items)
    .filter((x) => x.typeName === 'Registration')
    .map((x) => (x.typeName === 'Registration' ? x : undefined))
    .find((x) => (x ? x.id == id : false));
};

export const getPayments = (e: Event, regId: string): Payment[] => {
  let result: Payment[] = [];
  _(e.Items)
    .filter((x) => x.typeName === 'Payment' && x.regId == regId)
    .forEach((x) => {
      if (x.typeName === 'Payment') result = _.concat(result, x);
    });
  return result;
};

export const getNotes = (e: Event, regId: string): Note[] => {
  let result: Note[] = [];
  _(e.Items)
    .filter((x) => x.typeName === 'Note' && x.regId == regId)
    .forEach((x) => {
      if (x.typeName === 'Note') result = _.concat(result, x);
    });
  return result;
};

export const getSpecials = (e: Event, language: string): Special[] => {
  let result: Special[] = [];
  _(e.Items)
    .filter((x) => x.typeName === 'Special')
    .forEach((x) => {
      if (x.typeName === 'Special' && x.language == language) result = _.concat(result, x);
    });
  return result;
};

export const getSpecial = (e: Event, id: string): Special | undefined => {
  return _(e.Items)
    .filter((x) => x.typeName === 'Special')
    .map((x) => (x.typeName === 'Special' ? x : undefined))
    .find((x) => (x ? x.id == id : false));
};

export const getConditions = (e: Event, language: string): Condition[] => {
  let result: Condition[] = [];
  _(e.Items)
    .filter((x) => x.typeName === 'Condition')
    .forEach((x) => {
      if (x.typeName === 'Condition' && x.language == language) result = _.concat(result, x);
    });
  return result;
};

export const getCondition = (e: Event, id: string): Condition | undefined => {
  return _(e.Items)
    .filter((x) => x.typeName === 'Condition')
    .map((x) => (x.typeName === 'Condition' ? x : undefined))
    .find((x) => (x ? x.id == id : false));
};

export const getTeachers = (e: Event): Teacher[] => {
  let result: Teacher[] = [];
  _(e.Items)
    .filter((x) => x.typeName === 'Teacher')
    .forEach((x) => {
      if (x.typeName === 'Teacher') result = _.concat(result, x);
    });
  return result;
};

export const getTeacher = (e: Event, id: string): Teacher | undefined => {
  return _(e.Items)
    .filter((x) => x.typeName === 'Teacher')
    .map((x) => (x.typeName === 'Teacher' ? x : undefined))
    .find((x) => (x ? x.id == id : false));
};

// export const getNewIdx = (recs: record[]): number => {
//   const idx = _(recs)
//     .map((x) => getIdx(x.sk))
//     .max();
//   return idx ? idx + 1 : 1;
// };

export const getID = (): string => new ObjectID().toHexString();

// const getIdx = (x: string): number => {
//   const part = _(x).split('#').nth(1);
//   return part ? Number.parseInt(part) : 0;
// };

export type EventRecord = {
  typeName: 'EventRecord';
  id: string;
  tag: string;
  visible: boolean;
  registration_open: boolean;
  frontpage: boolean;
  start: string;
  image: string;
  location: string;
  latitude: number;
  longitude: number;
  address: string;
  href: string;
  eventtype: number;
  tarifs: Tarif[];
};

export type Translation = {
  typeName: 'Translation';
  id: string;
  tag: string;
  start: string;
  language: string;
  title: string;
  subtitle: string;
  description: string;
  transportation: string;
};

export type DateRange = {
  typeName: 'DateRange';
  id: string;
  from: string;
  to: string;
};

export type DateTranslation = {
  typeName: 'DateTranslation';
  id: string;
  dateRange: string;
  language: string;
  caption: string;
  comment: string;
};

export type Link = {
  typeName: 'Link';
  id: string;
  title: string;
  href: string;
};

export type Special = {
  typeName: 'Special';
  id: string;
  language: string;
  special: string;
};

export type Tarif = {
  id: string;
  untilDate: string;
  amount: number;
  slidingscale: boolean;
  range1: number;
  range2: number;
};

export type Teacher = {
  typeName: 'Teacher';
  id: string;
  teacher: string;
};

export type Condition = {
  typeName: 'Condition';
  id: string;
  language: string;
  condition: string;
};
