import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { EventPartContext } from './EventPartProvider';
import DatePicker from 'react-date-picker';
import { BsCalendar } from 'react-icons/bs';
import moment from 'moment';
import { EventContext } from './EventProvider';

const Registration = (): JSX.Element => {
  const { eventRecord, updateRecord } = useContext(EventPartContext);
  const { event, copyEvent, updateEvent } = useContext(EventContext);
  const { t, i18n } = useTranslation();
  const navi = useNavigate();

  const [href, setHref] = useState('');
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [front, setFront] = useState(false);
  const [show, setShow] = useState(false);
  const [start, setStart] = useState(new Date());
  const [tag, setTag] = useState('');
  const [eventType, setEventType] = useState(0);

  useEffect(() => {
    if (eventRecord) {
      setHref('/events/' + eventRecord.id + '/registration');
      setOpen(eventRecord.registration_open ? true : false); // bool conversion because of strange error message!
      setVisible(eventRecord.visible ? true : false); // bool conversion because of strange error message!
      setFront(eventRecord.frontpage ? true : false); // bool conversion because of strange error message!
      setStart(moment.utc(eventRecord.start).toDate());
      setTag(eventRecord.tag);
      setEventType(eventRecord.eventtype);
    }
  }, [eventRecord]);

  const updateOpen = (value: boolean) => {
    if (value !== open) {
      updateRecord('registration_open', value);
      setOpen(value);
    }
  };

  const updateVisible = (value: boolean) => {
    if (value !== visible) {
      updateRecord('visible', value);
      setVisible(value);
    }
  };

  const updateFront = (value: boolean) => {
    if (value !== front) {
      updateRecord('frontpage', value);
      setFront(value);
    }
  };

  const updateStart = (value: Date) => {
    if (value !== start && event) {
      const Items = _(event.Items)
        .map((x) => ('start' in x ? { ...x, start: moment(value).format('yyyy-MM-DD') } : x))
        .value();
      const id = event.id;
      //updateRecord('start', moment(value).format('yyyy-MM-DD'));
      updateEvent({ id, Items });
      setStart(value);
    }
  };

  const toTemplate = async () => {
    if (tag == 'active') copyEvent('template', ['Registration', 'Payment', 'Note']);
    if (tag == 'template') copyEvent('active', []);
  };

  const eventTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = _(event.target.value).toNumber();
    setEventType(value);
    updateRecord('eventtype', event.target.value);
  };

  return (
    <Fragment>
      {open ? (
        <div className="register">
          <a className="btn btn-warning btn-lg mt-3 mb-3" onClick={() => navi(href)}>
            {t('Registration')}
          </a>
        </div>
      ) : (
        <div></div>
      )}

      <Form.Switch
        id={'switchReg'}
        label={t("Open for registration")}
        checked={open}
        onChange={() => {
          updateOpen(!open);
        }}
      />

      <Form.Switch
        id={'switchReg'}
        label={t("visible in the net")}
        checked={visible}
        onChange={() => {
          updateVisible(!visible);
        }}
      />
      <Form.Group className="mb-3">
        <Form.Label className="me-2">{t('Startdate')}: </Form.Label>

        <DatePicker
          value={new Date(start)}
          onChange={updateStart}
          calendarIcon={<BsCalendar />}
          clearIcon={null}
          locale={i18n.language}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label className="me-2">Kategorie: </Form.Label>

        <Form.Select aria-label="Kategorie" value={eventType} onChange={eventTypeChange}>
          <option value="1">{t('cat1')}</option>
          <option value="2">{t('cat2')}</option>
          <option value="3">{t('cat3')}</option>
          <option value="4">{t('cat4')}</option>
          <option value="5">{t('cat5')}</option>
        </Form.Select>
      </Form.Group>

      <Form.Switch
        id={'switchReg'}
        label="Frontpage"
        checked={front}
        onChange={() => {
          updateFront(!front);
        }}
      />

      <Button onClick={toTemplate}>
        {tag == 'active' ? 'Als Vorlage nutzen' : 'Event aus Template anlegen'}
      </Button>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>Fertig. </Modal.Body>
      </Modal>
    </Fragment>
  );
};
export default Registration;
