import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { Accordion, Alert, Button, Col, Form, Nav, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaRegQuestionCircle, FaTrashAlt } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { Page } from "../../../types/Pages";
import { PagesContext } from "../../PageProvider";
import ImgUpload from "../Editor/imgUpload";
import { Texteditor } from "./Texteditor";
import { TextInput } from "./TextInput";

export const Custompage = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const { pages, savePage, deletePage } = useContext(PagesContext);
  const [selected, setSelected] = useState<Page>();
  const [error, setError] = useState("");
  const navi = useNavigate();
  const { name } = useParams();

  useEffect(() => {
    if (pages && pages.length > 0) {
      if (name) {
        setSelected(_(pages).find({ link: name }));
      } else {
        setSelected(pages[0]);
      }
    }
  }, [pages, i18n.language, name]);

  const newPage = () => {
    let idx = pages ? pages.length + 1 : 1;
    while (_(pages).find((x) => x.index == idx)) idx++;
    const page: Page = {
      typeName: "Page",
      id: "",
      title: "",
      link: "Link" + idx,
      css: "",
      html: "",
      index: idx,
      language: i18n.language,
      active: false,
    };
    setSelected(page);
    savePage(page);
    navi("/custompages/" + page.link);
  };

  if (pages)
    return (
      <Row>
        <Col md={2}>
          <h4>
            <>
              <span>{t("Custompages")}</span>
            </>
          </h4>
          <Nav className="flex-column">
            {_(pages)
              .map((page) => {
                return (
                  <Nav.Item key={page.id}>
                    <Nav.Link
                      className={
                        selected && page.link === selected.link
                          ? "text-success"
                          : ""
                      }
                      onClick={() => {
                        setError("");
                        navi("/custompages/" + page.link);
                      }}
                    >
                      {page.link}
                    </Nav.Link>
                  </Nav.Item>
                );
              })
              .value()}
            <Nav.Item>
              <Nav.Link className="text-dark" onClick={newPage}>
                {t("add Page")}
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col>
          {selected ? (
            <Form>
              <Row>
                <Col>
                  <a
                    type="button"
                    className="float-end mt-n1"
                    onClick={() => {
                      if (selected.link == "") {
                        setError("Link darf nicht leer sein");
                      } else {
                        navi("/page/" + selected.link);
                      }
                    }}
                  >
                    Preview
                  </a>
                </Col>
              </Row>{" "}
              <Row>
                <Col>
                  <Form.Switch
                    id={"switchCP"}
                    label={t("Visible on webpage")}
                    checked={selected.active}
                    className="float-end mt-2"
                    onChange={(e) => {
                      setError("");
                      const item = { ...selected, active: e.target.checked };
                      setSelected(item);
                      savePage(item);
                    }}
                  />
                </Col>
              </Row>
              {error ? (
                <Alert
                  variant="danger"
                  onClose={() => setError("")}
                  dismissible
                >
                  {error}
                </Alert>
              ) : (
                ""
              )}
              <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Url: {selected.link}</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col>
                        <Form.Label className="pt-1">Menuitem</Form.Label>
                        <TextInput
                          label="menuitem"
                          txt={selected.title}
                          onChange={(txt) => {
                            setError("");
                            const item = { ...selected, title: txt };
                            setSelected(item);
                            savePage(item);
                          }}
                        />
                      </Col>
                      <Col>
                        <Form.Group className=" mb-3" controlId="link">
                          <Form.Label className="pt-1">Url</Form.Label>
                          <FaRegQuestionCircle
                            className="ms-2"
                            size={16}
                            title={t("link")}
                          />
                          <TextInput
                            label="link"
                            txt={selected.link}
                            onChange={(txt) => {
                              setError("");
                              const item = { ...selected, link: txt };
                              setSelected(item);
                              savePage(item);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="idx">
                          <Form.Label>{t("Index")}</Form.Label>
                          <FaRegQuestionCircle
                            className="ms-2"
                            size={16}
                            title={t("index")}
                          />
                          <TextInput
                            label="index"
                            txt={selected.index.toString()}
                            onChange={(txt) => {
                              setError("");
                              const item = {
                                ...selected,
                                index: _.toNumber(txt),
                              };
                              setSelected(item);
                              savePage(item);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Label>{t("language")}</Form.Label>
                        <Form.Control
                          type="text"
                          value={selected.language}
                          readOnly
                          style={{
                            fontFamily: "Hack, monospace",
                            fontSize: "small",
                          }}
                        />
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header>Html</Accordion.Header>
                  <Accordion.Body>
                    <Texteditor
                      label="html"
                      txt={selected.html}
                      onChange={(txt) => {
                        setError("");
                        const item = { ...selected, html: txt };
                        setSelected(item);
                        savePage(item);
                      }}
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>CSS</Accordion.Header>
                  <Accordion.Body>
                    <Texteditor
                      label="css"
                      txt={selected.css}
                      onChange={(txt) => {
                        setError("");
                        const item = { ...selected, css: txt };
                        setSelected(item);
                        savePage(item);
                      }}
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>Upload Image</Accordion.Header>
                  <Accordion.Body>
                    <Form.Label>{t("Upload image")}</Form.Label>
                    <ImgUpload />
                    <Form.Label>
                      Url:{" "}
                      {"https://" +
                        process.env.REACT_APP_IMAGE_BUCKET +
                        ".s3." +
                        process.env.REACT_APP_REGION +
                        ".amazonaws.com/public/"}
                    </Form.Label>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Button
                variant="outline-danger"
                className="ms-3 float-end mt-3"
                disabled={selected.id === ""}
                onClick={() => {
                  setError("");
                  setSelected(undefined);
                  deletePage(selected.id);
                }}
              >
                <FaTrashAlt />
              </Button>
            </Form>
          ) : (
            ""
          )}
        </Col>
      </Row>
    );
  else return <></>;
};
