import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { PagesContext } from "./PageProvider";

export const CustomPage = (): JSX.Element => {
  const [html, setHtml] = useState("");
  const [css, setCss] = useState("");
  const { i18n } = useTranslation();

  const { pages } = useContext(PagesContext);
  const { name } = useParams();

  useEffect(() => {
    let mod;
    if (pages) {
      if (name) {
        mod = _(pages).find(
          (m) => m.link === name && m.language === i18n.language
        );
      } else {
        mod = _(pages).find(
          (m) => m.link === "home" && m.language === i18n.language
        );
      }
      if (mod) {
        setHtml(mod.html);
        setCss(mod.css);
      }
    }
  }, [pages, i18n.language, name]);

  return (
    <div>
      <div
        dangerouslySetInnerHTML={{
          __html: "<style> " + css + "</style>" + html,
        }}
      ></div>
    </div>
  );
};
