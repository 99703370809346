import moment from "moment";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaGlobe, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ListContext } from "./ListProvider";
import { getEventRecord, getTranslation } from "../../../types/evnt";

type Props = {
  idx: number;
};

const Entry = (props: Props): JSX.Element => {
  const navi = useNavigate();
  const { i18n } = useTranslation();
  const { list = [], deleteTemplate } = useContext(ListContext);
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [frontpage, setFrontpage] = useState(false);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [start, setStart] = useState("");

  if (list && list.length > 0) {
    const View = () => {
      navi("/backend/" + list[props.idx].id + "/editor");
    };

    const Remove = () => {
      deleteTemplate(props.idx);
    };

    useEffect(() => {
      if (list && list.length > props.idx) {
        const event = list[props.idx];
        if (event) {
          const rec = getEventRecord(event);
          if (rec) {
            setVisible(rec.visible);
            setOpen(rec.registration_open);
            setFrontpage(rec.frontpage);
            setStart(rec.start);
          }
          const tx = getTranslation(event, i18n.language);
          if (tx) {
            setTitle(tx.title);
            setSubtitle(tx.subtitle);
          }
        }
      }
    }, [list, i18n.language]);

    return (
      <tr
        className={
          visible && open
            ? "bg-success bg-opacity-10"
            : visible && !open
            ? "bg-warning bg-opacity-10"
            : ""
        }
      >
        <td>
          {visible ? (
            <h4>
              <FaGlobe className={frontpage ? "text-danger" : ""} size={21} />{" "}
            </h4>
          ) : (
            ""
          )}
        </td>
        <td onClick={View}>
          <h4>
            <div> {title} </div>
            <small> {subtitle}</small>
          </h4>
        </td>
        {/* <td>
          <RiFileAddLine title="Erstelle neuen Event" size={42} onClick={toEvent} />
        </td> */}
        <td>{moment(start).toDate().toLocaleDateString()}</td>
        <td>
          <FaTimes
            title="Delete event"
            className="text-danger"
            size={21}
            onClick={Remove}
          />
        </td>
      </tr>
    );
  } else return <Fragment></Fragment>;
};

export default Entry;
