import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTranslation } from '../../../types/evnt';
import { EventPartContext } from './EventPartProvider';
import InlineEdit from './InlineEdit';

const Title = (): JSX.Element => {
  const { i18n } = useTranslation();
  const { event, update } = useContext(EventPartContext);

  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [isTemplate, setIsTemplate] = useState(false);

  useEffect(() => {
    if (event) {
      const translation = getTranslation(event, i18n.language);
      if (translation) {
        setTitle(translation.title);
        setSubtitle(translation.subtitle);
        setIsTemplate(translation.tag == 'template');
      }
    }
  }, [event, i18n.language]);

  const saveTitle = (text: string) => {
    if (event && text !== title) {
      const translation = getTranslation(event, i18n.language);
      if (translation) {
        update('Translation', translation.id, 'title', text);
        setTitle('Speichern...');
      }
    }
  };

  const saveSubtitle = (text: string) => {
    if (event && text !== subtitle) {
      const translation = getTranslation(event, i18n.language);
      if (translation) {
        update('Translation', translation.id, 'subtitle', text);
        setSubtitle('Speichern...');
      }
    }
  };

  return (
    <div>
      {isTemplate ? <h3 className="text-danger">Template</h3> : ''}
      <h1>
        <InlineEdit text={title} onSetText={saveTitle} />
      </h1>
      <h6 className="text-muted">
        <InlineEdit text={subtitle} onSetText={saveSubtitle} />
      </h6>
    </div>
  );
};

export default Title;
