import { Fragment, useContext, useEffect, useState } from "react";
import { Row, Col, Nav } from "react-bootstrap";
import { Registration } from "../../../types/Registration";
import { getRegistration } from "../../../types/evnt";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Status } from "./Status";
import { Participant } from "./Participant";
import { EventPartContext } from "../Editor/EventPartProvider";
import { PaymentList } from "./Payments";
import { Message } from "./Message";
import { Emails } from "./Email";
import { MailsContext } from "../Mailtemplates/MailsProvider";
import _ from "lodash";
import { MailTemplate } from "../../../types/MailTemplate";
import { Notes } from "./Notes";

export const RegistrationAdmin = (): JSX.Element => {
  const { event } = useContext(EventPartContext);
  const { mailTemplates } = useContext(MailsContext);
  const [reg, setReg] = useState<Registration>();
  const { regid } = useParams();
  const { t } = useTranslation();
  const [template, setTemplate] = useState<MailTemplate>();

  useEffect(() => {
    if (event && regid) {
      const reg = getRegistration(event, regid);
      if (reg) setReg(reg);
    }
  }, [event]);

  return (
    <Fragment>
      {reg && regid ? (
        <>
          <Row>
            <Col sm={4} md={3}>
              <Status id={regid} />
              <Participant id={regid} />
              <Message id={regid} />
            </Col>
            <Col sm={4} md={6}>
              <PaymentList />
            </Col>
            <Col sm={4} md={3}>
              <Notes />
            </Col>
          </Row>

          <Row>
            <Col>
              <h3 className="">{t("Send email")}</h3>

              <Emails regid={regid} template={template} />
            </Col>
            <Col sm={2}>
              <h3 className="">Emailtemplates</h3>
              <Nav className="flex-column">
                {_(mailTemplates)
                  .map((mt) => {
                    return (
                      <Nav.Item key={mt.id}>
                        <Nav.Link
                          onClick={() => {
                            setTemplate(mt);
                          }}
                        >
                          {mt.name}
                        </Nav.Link>
                      </Nav.Item>
                    );
                  })
                  .value()}
              </Nav>
            </Col>
          </Row>
        </>
      ) : (
        ""
      )}
    </Fragment>
  );
};
