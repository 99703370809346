import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getSpecials, Special } from '../../../types/evnt';
import { EventContext } from './EventProvider';

const Specials = (): JSX.Element => {
  const { event } = useContext(EventContext);
  const { i18n,t } = useTranslation();
  const [records, setRecords] = useState<Special[]>([]);
  useEffect(() => {
    if (event) setRecords(getSpecials(event, i18n.language));
  }, [event, i18n.language]);

  return (
    <div className="specials">
      <Table>
        <caption>
          <h4>{t('specials')}</h4>
        </caption>
        <tbody>
          {_.map(
            _(records)
              .sortBy((x) => x.id)
              .value(),
            (rec) => {
              if (rec)
                return (
                  <tr key={rec.id}>
                    <td>{rec.special}</td>
                  </tr>
                );
            }
          )}
        </tbody>
      </Table>
    </div>
  );
};
export default Specials;
