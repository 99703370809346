import _ from "lodash";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Card, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaPlus, FaTimes } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { getNotes } from "../../../types/evnt";
import { Note } from "../../../types/Registration";
import { EventPartContext } from "../Editor/EventPartProvider";
import InlineTextArea from "../Editor/InlineTextArea";

export const Notes = (): JSX.Element => {
  const { event, create, update, remove } = useContext(EventPartContext);
  //const [reg, setReg] = useState<Registration>();
  const [notes, setNotes] = useState<Note[]>([]);
  const { regid } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (event && regid) {
      const notes = getNotes(event, regid);
      setNotes(notes);
    }
  }, [event]);

  const deleteNote = (id: string) => {
    if (event) {
      remove("Note", id);
    }
  };

  const AddNote = () => {
    if (regid) {
      const newNote: Note = {
        typeName: "Note",
        id: "",
        regId: regid,
        datum: moment(Date.now()).format("YYYY-MM-DD hh:mm"),
        text: "Notiz",
      };
      create("Note", _.omit(newNote, "id"));
    }
  };

  return (
    <Card>
      <Card.Header>
        <h4>
          {t("Notes")}
          <FaPlus
            className="float-end text-success"
            title="Notiz hinzufügen"
            onClick={AddNote}
          />
        </h4>
      </Card.Header>

      <ListGroup>
        {notes.map((note) => {
          return (
            <ListGroup.Item key={note.id}>
              <div className="fw-bold">
                {note.datum}
                <FaTimes
                  className="float-end text-danger"
                  title="Notiz löschen"
                  onClick={() => deleteNote(note.id)}
                />
              </div>
              <InlineTextArea
                text={note.text}
                onSetText={(text) => {
                  if (regid) {
                    update("Note", note.id, "text", text);
                  }
                }}
              />
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </Card>
  );
};
