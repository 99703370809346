import _ from 'lodash';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import 'leaflet/dist/leaflet.css';
import L, { LatLngTuple } from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import InlineEdit from './InlineEdit';
import InlineTextArea from './InlineTextArea';
import { EventPartContext } from './EventPartProvider';
import { getTranslation } from '../../../types/evnt';

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});
L.Marker.prototype.options.icon = DefaultIcon;

const Transportation = (): JSX.Element => {
  const { i18n } = useTranslation();
  const [transport, setTransport] = useState('');
  const [name, setName] = useState('');
  const { event, update } = useContext(EventPartContext);
  const { eventRecord, updateRecord } = useContext(EventPartContext);
  const [latlng, setLatlng] = useState<LatLngTuple>([51.505, -0.09]);
  const mapContainer = useRef<HTMLDivElement | null>();
  const [mapref, setMapref] = useState<L.Map>();

  useEffect(() => {
    if (mapref) mapref.remove();
    if (mapContainer.current) {
      const tiles =
        'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}';
      const attribution =
        '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>';
      const accessToken =
        'pk.eyJ1IjoianNlaWRlbDY3IiwiYSI6ImNqczB1aTU1NjA0czM0M28xZjIzNHU0OW0ifQ.auDxh4Wf6WRiltE0CvSwaw';
      const id = 'mapbox/streets-v11';
      const map = L.map(mapContainer.current, { attributionControl: true }).setView(latlng, 14);
      L.tileLayer(tiles, {
        maxZoom: 18,
        attribution,
        id,
        tileSize: 512,
        zoomOffset: -1,
        accessToken
      }).addTo(map);
      L.marker(latlng).addTo(map).bindTooltip(name).openTooltip();
      setMapref(map);
    }
  }, [latlng]);

  useEffect(() => {
    if (event) {
      const translation = getTranslation(event, i18n.language);
      if (translation) setTransport(translation.transportation);
    }
    if (eventRecord) {
      setName(eventRecord.location);
      setLatlng([eventRecord.latitude, eventRecord.longitude]);
    }
  }, [i18n.language, event, eventRecord]);

  const updateLatitude = (value: string) => {
    if (!isNaN(_.toNumber(value))) {
      setLatlng([_.toNumber(value), latlng[1]]);
      updateRecord('latitude', value);
    }
  };

  const updateLongitude = (value: string) => {
    if (!isNaN(_.toNumber(value))) {
      setLatlng([latlng[0], _.toNumber(value)]);
      updateRecord('longitude', value);
    }
  };

  const updateTransport = (text: string) => {
    if (event && text !== transport) {
      const translation = getTranslation(event, i18n.language);
      if (translation) {
        update('Translation', translation.id, 'transportation', text);
        setTransport(text);
      }
    }
  };

  return (
    <Fragment>
      {event && eventRecord && latlng ? (
        <Card className="transportation">
          <div
            style={{ padding: 0, margin: 0, width: '100%', height: '40vh' }}
            ref={(el) => (mapContainer.current = el)}></div>
          <div>
            <span>
              <InlineEdit text={latlng[0].toString()} onSetText={updateLatitude} />
            </span>
            -
            <span>
              <InlineEdit text={latlng[1].toString()} onSetText={updateLongitude} />
            </span>
          </div>
          <Fragment>
            <Card.Body>
              <InlineTextArea text={transport} onSetText={updateTransport} />
            </Card.Body>
          </Fragment>
        </Card>
      ) : (
        ''
      )}
    </Fragment>
  );
};

export default Transportation;
