import { FormEvent, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useAuth from "./useAuth";
import PasswordChecklist from "react-password-checklist";
import { useTranslation } from "react-i18next";

const ChangePassword = (): JSX.Element => {
  const [error] = useState("");
  const [showError, setShowError] = useState(false);
  const [newPass, setNewPass] = useState("");
  const [passConfirm, setPassConfirm] = useState("");
  const navigate = useNavigate();
  const auth = useAuth();
  const { t } = useTranslation();

  const validateForm = () => {
    if (newPass !== passConfirm) {
      // setError('Die Passwörter snd unterschiedlich');
      // setShowError(true);
      return false;
    }
    const checkp =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`])\S{8,99}$/;
    if (!checkp.test(newPass)) {
      // setError('Das Passwort muß min. 8 Zeichen lang sein und Groß-und Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten.');
      // setShowError(true);
      return false;
    }
    return true;
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("ccc");
    if (auth.user) {
      auth.completeNewPassword(newPass, () => {
        navigate("/backend?archived=false");
      });
    }
    return false;
  };

  return (
    <div className="Login">
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="newPass">
          <Form.Label>Password</Form.Label>
          <Form.Control
            size="lg"
            autoFocus
            type="password"
            value={newPass}
            onChange={(e) => setNewPass(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mt-4" controlId="password">
          <Form.Label>Password wiederholen</Form.Label>
          <Form.Control
            size="lg"
            type="password"
            value={passConfirm}
            onChange={(e) => setPassConfirm(e.target.value)}
          />
        </Form.Group>

        <PasswordChecklist
          className="mt-3"
          rules={["minLength", "specialChar", "number", "capital", "match"]}
          minLength={8}
          value={newPass}
          valueAgain={passConfirm}
          messages={{
            minLength: t("has more than 8 characters"),
            specialChar: t("has special characters"),
            number: t("has number"),
            capital: t("has capital letter"),
            match: t("Passwords match"),
          }}
        />

        <Button
          className="mt-3"
          size="lg"
          type="submit"
          disabled={!validateForm()}
        >
          Ändern
        </Button>
        {showError ? (
          <Alert
            variant="danger"
            className="mt-4"
            onClose={() => setShowError(false)}
            dismissible
          >
            <p>{error} </p>
          </Alert>
        ) : (
          ""
        )}
      </Form>
    </div>
  );
};

export default ChangePassword;
